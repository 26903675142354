import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Animated } from "react-animated-css";
import getMyCerts from "../../data/queries/getMyCerts";
import { useQuery } from "@tanstack/react-query";
import { classNames } from "../../services/utils";
import { UserLevelContext } from "../../context/UserLevelContext";
import getImage from "../../components/GetImage/GetImage";

export default function Home({
  username,
  company,
  companyLogo,
  modules,
  setSelectedIndex,
  userLoggedIn,
}) {
  const navigate = useNavigate();
  const [certExpiry, setCertExpiry] = useState("N/A");
  const [expiresSoon, setExpiresSoon] = useState(false);
  const [hasExpired, setHasExpired] = useState("");

  const userLevel = useContext(UserLevelContext);

  const today = new Date().toLocaleDateString("en-GB");

  const totalmodules = modules ? modules.length : 1;
  const completed = modules
    ? modules.filter((module) => module.status === "COMPLETE").length
    : 0;
  const percentageComplete =
    totalmodules !== 0 ? ((completed / totalmodules) * 100).toFixed(0) : 0;

  const totalQuestionsAnswered = modules
    ? modules.reduce(
        (acc, module) =>
          acc +
          (module.state.result && module.status === "COMPLETE"
            ? module.state.result.total
            : 0),
        0
      )
    : 0;
  const totalQuestionsCorrect = modules
    ? modules.reduce(
        (acc, module) =>
          acc +
          (module.state.result && module.status == "COMPLETE"
            ? module.state.result.correct
            : 0),
        0
      )
    : 0;
  const percentageCorrect =
    totalQuestionsAnswered > 0
      ? ((totalQuestionsCorrect / totalQuestionsAnswered) * 100).toFixed(0)
      : 0;

  const { data: certs, status } = useQuery(["certs"], () => getMyCerts());

  useEffect(() => {
    if (certs && certs.data && certs.data.length > 0) {
      setCertExpiry(new Date(certs.data[0].expiry).toLocaleDateString("en-GB"));
      setExpiresSoon(certs.data[0].isRenewable);
      setHasExpired(certs.data[0].hasExpired);
    }
  }, [certs]);

  return (
    <>
      <div className="min-h-full">
        <div className="flex flex-col flex-1">
          <main className="flex-1 pb-8">
            <Animated animationIn="fadeInUp" isVisible={true}>
              <div className="bg-white shadow">
                <div className="px-2 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-2">
                  <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                    <div className="flex-1 min-w-0">
                      <div className="flex items-center">
                        <div>
                          <div className="h-16 w-16 inline-flex overflow-hidden relative justify-center items-center bg-fathom-blue rounded-full mr-5">
                            <span className="text-3xl text-white">
                              {username &&
                                username.split(" ")[0].substr(0, 1) +
                                  (username.split(" ")[1] &&
                                    username.split(" ")[1].substr(0, 1))}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center flex-1 min-w-0">
                          <div>
                            <div className="flex items-center">
                              <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                                Hello, {username}
                              </h1>
                            </div>
                            <dl className="mt-1 flex flex-col sm:flex-row sm:flex-wrap">
                              <dt className="sr-only">Company</dt>
                              <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                                <svg
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-fathom-blue"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                {company}
                              </dd>
                            </dl>
                          </div>
                        </div>
                        <div>
                          {companyLogo && (
                            <img
                              className="h-16"
                              src={getImage({
                                image: companyLogo,
                              })}
                              alt={company}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Animated>

            {userLevel === 1 && (
              <Animated
                animationIn="fadeInUp"
                animationInDelay={500}
                isVisible={true}
              >
                <div className="mt-8">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Quick Info
                  </h3>
                  <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
                    <div className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900">
                        Course Complete
                      </dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-fathom-blue">
                          {completed}
                          <span className="ml-2 text-sm font-medium text-gray-500">
                            {" "}
                            out of {totalmodules} modules complete
                          </span>
                        </div>

                        <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                          {percentageComplete}%
                        </div>
                      </dd>
                    </div>

                    <div className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900">
                        Average Score
                      </dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-fathom-blue">
                          {percentageCorrect}%
                        </div>
                      </dd>
                    </div>

                    <div className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900">
                        Certification Valid Until
                      </dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div
                          className={classNames(
                            hasExpired ? "text-red-800" : "text-fathom-blue",
                            "flex items-baseline text-2xl font-semibold"
                          )}
                        >
                          {certExpiry}
                        </div>

                        {expiresSoon && (
                          <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800 md:mt-2 lg:mt-0">
                            {hasExpired ? "Expired" : "Expires soon"}
                          </div>
                        )}
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="mt-8">
                  <h3 className="text-lg leading-6 font-medium text-gray-900 mb-5">
                    Quick Actions
                  </h3>
                  <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-3 sm:gap-px">
                    <div className="rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                      <div>
                        <span className="rounded-lg inline-flex p-3 bg-teal-50 text-teal-700 ring-4 ring-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="mt-8">
                        <h3 className="text-lg font-medium">
                          <a
                            className="focus:outline-none cursor-pointer"
                            onClick={() => {
                              setSelectedIndex(1);
                              navigate("/course");
                            }}
                          >
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            ></span>
                            My Course
                          </a>
                        </h3>
                        <p className="mt-2 text-sm text-gray-500">
                          In here you will find all of the modules that have
                          been assigned to your role.
                        </p>
                      </div>
                      <span
                        className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                        aria-hidden="true"
                      >
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                        </svg>
                      </span>
                    </div>
                    <div className="sm:rounded-tr-lg relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                      <div>
                        <span className="rounded-lg inline-flex p-3 bg-sky-50 text-sky-700 ring-4 ring-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="mt-8">
                        <h3 className="text-lg font-medium">
                          <a
                            className="focus:outline-none cursor-pointer"
                            onClick={() => {
                              setSelectedIndex(3);
                              navigate("/certifications");
                            }}
                          >
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            ></span>
                            My Certifications
                          </a>
                        </h3>
                        <p className="mt-2 text-sm text-gray-500">
                          Upon successful completion of the course here’s where
                          you will find your certificate!
                        </p>
                      </div>
                      <span
                        className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                        aria-hidden="true"
                      >
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                        </svg>
                      </span>
                    </div>
                    <div className="sm:rounded-tr-lg relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                      <div>
                        <span className="rounded-lg inline-flex p-3 bg-yellow-50 text-yellow-700 ring-4 ring-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="mt-8">
                        <h3 className="text-lg font-medium">
                          <a
                            className="focus:outline-none cursor-pointer"
                            onClick={() => {
                              setSelectedIndex(-1);
                              navigate("/profile");
                            }}
                          >
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            ></span>
                            My Profile
                          </a>
                        </h3>
                        <p className="mt-2 text-sm text-gray-500">
                          View and amend your profile details here.
                        </p>
                      </div>
                      <span
                        className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                        aria-hidden="true"
                      >
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </Animated>
            )}

            {userLevel > 1 && (
              <Animated
                animationIn="fadeInUp"
                animationInDelay={500}
                isVisible={true}
              >
                <div className="mt-8">
                  <h3 className="text-lg leading-6 font-medium text-gray-900 mb-5">
                    Quick Actions
                  </h3>
                  {userLoggedIn.company.companyType == "training" && (
                    <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-1 sm:gap-px">
                      <div className="rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                        <div>
                          <span className="rounded-lg inline-flex p-3 bg-teal-50 text-teal-700 ring-4 ring-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                            </svg>
                          </span>
                        </div>
                        <div className="mt-8">
                          <h3 className="text-lg font-medium">
                            <a
                              className="focus:outline-none cursor-pointer"
                              onClick={() => {
                                setSelectedIndex(1);
                                navigate("/users");
                              }}
                            >
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              ></span>
                              My Users
                            </a>
                          </h3>
                          <p className="mt-2 text-sm text-gray-500">
                            View and manage the users that are assigned to your
                            company.
                          </p>
                        </div>
                        <span
                          className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                          aria-hidden="true"
                        >
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                          </svg>
                        </span>
                      </div>
                    </div>
                  )}
                  {userLoggedIn.company.companyType ==
                    "trainingAndManagement" && (
                    <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
                      <div className="rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                        <div>
                          <span className="rounded-lg inline-flex p-3 bg-teal-50 text-teal-700 ring-4 ring-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                            </svg>
                          </span>
                        </div>
                        <div className="mt-8">
                          <h3 className="text-lg font-medium">
                            <a
                              className="focus:outline-none cursor-pointer"
                              onClick={() => {
                                setSelectedIndex(1);
                                navigate("/users");
                              }}
                            >
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              ></span>
                              My Users
                            </a>
                          </h3>
                          <p className="mt-2 text-sm text-gray-500">
                            View and manage the users that are assigned to your
                            company.
                          </p>
                        </div>
                        <span
                          className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                          aria-hidden="true"
                        >
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                          </svg>
                        </span>
                      </div>

                      <div className="sm:rounded-tr-lg relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                        <div>
                          <span className="rounded-lg inline-flex p-3 bg-sky-50 text-sky-700 ring-4 ring-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                              />
                            </svg>
                          </span>
                        </div>
                        {userLevel == 2 && (
                          <div className="mt-8">
                            <h3 className="text-lg font-medium">
                              <a
                                className="focus:outline-none cursor-pointer"
                                onClick={() => {
                                  setSelectedIndex(7);
                                  navigate(
                                    `/vessel/${userLoggedIn.vessel?.id}`
                                  );
                                }}
                              >
                                <span
                                  className="absolute inset-0"
                                  aria-hidden="true"
                                ></span>
                                My Vessel - {userLoggedIn.vessel?.name}
                              </a>
                            </h3>
                            <p className="mt-2 text-sm text-gray-500">
                              Manage all the vessels that are assigned to your
                              company.
                            </p>
                          </div>
                        )}
                        {userLevel > 2 && (
                          <div className="mt-8">
                            <h3 className="text-lg font-medium">
                              <a
                                className="focus:outline-none cursor-pointer"
                                onClick={() => {
                                  setSelectedIndex(3);
                                  navigate("/fleet");
                                }}
                              >
                                <span
                                  className="absolute inset-0"
                                  aria-hidden="true"
                                ></span>
                                My Fleet
                              </a>
                            </h3>
                            <p className="mt-2 text-sm text-gray-500">
                              Manage all the vessels that are assigned to your
                              company.
                            </p>
                          </div>
                        )}
                        <span
                          className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                          aria-hidden="true"
                        >
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                          </svg>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </Animated>
            )}
          </main>
        </div>
      </div>
    </>
  );
}
