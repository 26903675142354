import { Fragment, useEffect, useRef, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShip } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ErrorDialog from "../../components/Dialogs/ErrorDialog";
import { CompanyContext } from "../../context/CompanyContext";

export default function AddUpdateVesselModal({
  showModal,
  setShowModal,
  isUpdate,
  vesselId,
  editVessel,
}) {
  const queryClient = useQueryClient();
  const cancelButtonRef = useRef(null);
  const companyContext = useContext(CompanyContext);

  // Define state variables for input fields
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [email, setEmail] = useState("");
  const [length, setLength] = useState(0);
  const [tails, setTails] = useState(0);
  const [steps, setSteps] = useState(0);
  const [imo, setImo] = useState("");
  const [spreaders, setSpreaders] = useState(0);
  const [rubberSteps, setRubberSteps] = useState("");
  const [securing, setSecuring] = useState("");
  const [telephone, setTelephone] = useState("");
  const [photoFile, setPhotoFile] = useState(null);
  const [marineSuperintendent, setMarineSuperintendent] = useState("");
  const [marineSuperintendentEmail, setMarineSuperintendentEmail] = useState("");
  const [technicalSuperintendent, setTechnicalSuperintendent] = useState("");
  const [technicalSuperintendentEmail, setTechnicalSuperintendentEmail] = useState("");
  const [technicalOfficer, setTechnicalOfficer] = useState("");
  const [technicalOfficerEmail, setTechnicalOfficerEmail] = useState("");
  
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorList, setErrorList] = useState([]);

  useEffect(() => {
    if (
      isUpdate &&
      editVessel != null &&
      editVessel !== undefined &&
      editVessel != {}
    ) {
      setName(editVessel.name);
      setType(editVessel.type);
      setEmail(editVessel.email);
      setLength(editVessel.length);
      setTails(editVessel.tails);
      setSteps(editVessel.steps);
      setImo(editVessel.IMO);
      setSpreaders(editVessel.spreaders);
      setRubberSteps(editVessel.rubberSteps);
      setSecuring(editVessel.securing);
      setTelephone(editVessel.telephone);
      setMarineSuperintendent(editVessel.marineSuperintendent);
      setMarineSuperintendentEmail(editVessel.marineSuperintendentEmail);
      setTechnicalSuperintendent(editVessel.technicalSuperintendent);
      setTechnicalSuperintendentEmail(editVessel.technicalSuperintendentEmail);
      setTechnicalOfficer(editVessel.technicalOfficer);
      setTechnicalOfficerEmail(editVessel.technicalOfficerEmail);
    }
  }, [isUpdate, editVessel]);

  // Specific event handlers
  const handleLengthChange = (e) => {
    const inputValue = e.target.value;

    // Allow only numbers and up to 2 decimal places
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(inputValue) || inputValue === "") {
      setLength(inputValue);
    }
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setPhotoFile(file);
  };

  // Mutations
  const addVesselMutation = useMutation(
    () => {
      const formData = new FormData();
      formData.append("company", companyContext.id);
      formData.append("name", name);
      formData.append("type", type);
      formData.append("email", email);
      formData.append("length", length);
      formData.append("tails", tails);
      formData.append("steps", steps);
      formData.append("IMO", imo);
      formData.append("spreaders", spreaders);
      formData.append("rubberSteps", rubberSteps);
      formData.append("securing", securing);
      formData.append("telephone", telephone);
      if (photoFile) {
      formData.append(`files.photo`, photoFile, photoFile.name);
      }
      formData.append("marineSuperintendent", marineSuperintendent);
      formData.append("marineSuperintendentEmail", marineSuperintendentEmail);
      formData.append("technicalSuperintendent", technicalSuperintendent);
      formData.append("technicalSuperintendentEmail", technicalSuperintendentEmail);
      formData.append("technicalOfficer", technicalOfficer);
      formData.append("technicalOfficerEmail", technicalOfficerEmail);

      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/vessels/add`,
        formData,
        {
          headers: {
            Accept: "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["vessels"]);
        // Clear down form after succesful submit
        setName("");
        setType("");
        setEmail("");
        setLength(0);
        setTails(0);
        setSteps(0);
        setImo("");
        setSpreaders(0);
        setRubberSteps("");
        setSecuring("");
        setTelephone("");
        setPhotoFile(null);
        setMarineSuperintendent("");
        setMarineSuperintendentEmail("");
        setTechnicalSuperintendent("");
        setTechnicalSuperintendentEmail("");
        setTechnicalOfficer("");
        setTechnicalOfficerEmail("");

        setShowModal(false);
      },
    }
  );

  const updateVesselMutation = useMutation(
    () => {
      const formData = new FormData();

      formData.append("name", name);
      formData.append("type", type);
      formData.append("email", email);
      formData.append("length", length);
      formData.append("tails", tails);
      formData.append("steps", steps);
      formData.append("IMO", imo);
      formData.append("spreaders", spreaders);
      formData.append("rubberSteps", rubberSteps);
      formData.append("securing", securing);
      formData.append("telephone", telephone);
      if (photoFile) {
        formData.append(`files.photo`, photoFile, photoFile.name);
      }
      formData.append("marineSuperintendent", marineSuperintendent);
      formData.append("marineSuperintendentEmail", marineSuperintendentEmail);
      formData.append("technicalSuperintendent", technicalSuperintendent);
      formData.append("technicalSuperintendentEmail", technicalSuperintendentEmail);
      formData.append("technicalOfficer", technicalOfficer);
      formData.append("technicalOfficerEmail", technicalOfficerEmail);

      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/vessels/update/${vesselId}`,
        formData,
        {
          headers: {
            Accept: "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["vessels"]);
        // Clear down form after succesful submit
        setName("");
        setType("");
        setEmail("");
        setLength(0);
        setTails(0);
        setSteps(0);
        setImo("");
        setSpreaders(0);
        setRubberSteps("");
        setSecuring("");
        setTelephone("");
        setPhotoFile(null);
        setMarineSuperintendent("");
        setMarineSuperintendentEmail("");
        setTechnicalSuperintendent("");
        setTechnicalSuperintendentEmail("");
        setTechnicalOfficer("");
        setTechnicalOfficerEmail("");
        
        setShowModal(false);
      },
    }
  );

  // After Clicking Submit on Form
  const clickSubmit = () => {
    setErrorList([]);
    let errors = [];

    if (name === "" || name === null || name === undefined) {
      errors.push("Name is required");
    }
    if (type === "" || type === null || type === undefined) {
      errors.push("Type is required");
    }
    if (email === "" || email === null || email === undefined) {
      errors.push("Email is required");
    }
    if (telephone === "" || telephone === null || telephone === undefined) {
      errors.push("Telephone is required");
    }
    if (!/^\d{7}$/.test(imo)) {
      errors.push("IMO is required and must be 7 digits");
    }
    if (
      length <= 0 ||
      length === "" ||
      length === null ||
      length === undefined
    ) {
      errors.push("Length must be greater than 1");
    }
    if (tails <= 1 || tails === "" || tails === null || tails === undefined) {
      errors.push("Tails must be greater than 1");
    }
    if (steps <= 1 || steps === "" || steps === null || steps === undefined) {
      errors.push("Steps must be greater than 1");
    }
    if (spreaders <= 1 || spreaders === "" || spreaders === null) {
      errors.push("Spreaders must be greater than 1");
    }
    if (
      rubberSteps === "" ||
      rubberSteps === null ||
      rubberSteps === undefined
    ) {
      errors.push("Rubber Steps is required");
    }
    if (securing === "" || securing === null || securing === undefined) {
      errors.push("Securing is required");
    }

    if (errors.length > 0) {
      setErrorMessage(errors.join(", "));
      setErrorDialogOpen(true);
    } else {
      if (isUpdate) {
        updateVesselMutation.mutate();
      } else {
        addVesselMutation.mutate();
      }
      setShowModal(false);
    }
  };

  return (
    <div>
      <Transition.Root show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setShowModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-fathom-blue">
                    <FontAwesomeIcon
                      icon={faShip}
                      className="h-6 w-6 text-white"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {isUpdate ? "Update" : "Add"} Vessel
                    </Dialog.Title>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <div className="mt-5">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                      />
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="type"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Type
                      </label>
                      <select
                        id="type"
                        name="type"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                      >
                        <option value="" disabled>
                          Select Type
                        </option>
                        <option value="THETIS">THETIS</option>
                        <option value="NEREUS">NEREUS</option>
                        <option value="OTHER">OTHER</option>
                      </select>
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                      />
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="telephone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Telephone
                      </label>
                      <input
                        type="text"
                        name="telephone"
                        id="telephone"
                        value={telephone}
                        onChange={(e) => setTelephone(e.target.value)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                      />
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="imo"
                        className="block text-sm font-medium text-gray-700"
                      >
                        IMO
                      </label>
                      <input
                        type="text"
                        name="imo"
                        id="imo"
                        value={imo}
                        onChange={(e) => setImo(e.target.value)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                      />
                    </div>

                    <div className="mt-5">
                      <label
                        htmlFor="photo"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {isUpdate ? "Replace Photo" : "Photo"}
                      </label>
                      <input
                        type="file"
                        name="photo"
                        id="photo"
                        accept="image/*"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                        onChange={handlePhotoChange}
                      />
                    </div>

                    <div className="mt-5">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Marine Superintendent Name
                      </label>
                      <input
                        type="text"
                        name="marineSuperintendent"
                        id="marineSuperintendent"
                        value={marineSuperintendent}
                        onChange={(e) => setMarineSuperintendent(e.target.value)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                      />
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Technical Superintendent Name
                      </label>
                      <input
                        type="text"
                        name="technicalSuperintendent"
                        id="technicalSuperintendent"
                        value={technicalSuperintendent}
                        onChange={(e) => setTechnicalSuperintendent(e.target.value)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                      />
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Technical Officer Name
                      </label>
                      <input
                        type="text"
                        name="technicalOfficer"
                        id="technicalOfficer"
                        value={technicalOfficer}
                        onChange={(e) => setTechnicalOfficer(e.target.value)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                      />
                    </div>
                    
                  </div>
                  <div>
                    <div className="mt-5">
                      <label
                        htmlFor="length"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Length
                      </label>
                      <div className="flex items-center mt-1">
                        <input
                          type="number"
                          id="length"
                          value={length}
                          onChange={handleLengthChange}
                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                          min={1}
                        />
                        <span className="ml-1 text-gray-500">m</span>
                      </div>
                    </div>

                    <div className="mt-5">
                      <label
                        htmlFor="tails"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Tails
                      </label>
                      <input
                        type="number"
                        name="tails"
                        id="tails"
                        value={tails}
                        onChange={(e) => setTails(parseInt(e.target.value))}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                        min={1}
                      />
                    </div>

                    <div className="mt-5">
                      <label
                        htmlFor="steps"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Steps
                      </label>
                      <input
                        type="number"
                        name="steps"
                        id="steps"
                        value={steps}
                        onChange={(e) => setSteps(parseInt(e.target.value))}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                        min={1}
                      />
                    </div>

                    <div className="mt-5">
                      <label
                        htmlFor="spreaders"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Spreaders
                      </label>
                      <input
                        type="number"
                        name="spreaders"
                        id="spreaders"
                        value={spreaders}
                        onChange={(e) => setSpreaders(parseInt(e.target.value))}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                        min={1}
                      />
                    </div>

                    <div className="mt-5">
                      <label
                        htmlFor="rubberSteps"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Rubber Steps
                      </label>
                      <select
                        id="rubberSteps"
                        name="rubberSteps"
                        value={rubberSteps}
                        onChange={(e) => setRubberSteps(e.target.value)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                      >
                        <option value="" disabled>
                          Select Rubber Steps
                        </option>
                        <option value="4">4</option>
                        <option value="5/4">5/4</option>
                      </select>
                    </div>

                    <div className="mt-5">
                      <label
                        htmlFor="securing"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Securing
                      </label>
                      <select
                        id="securing"
                        name="securing"
                        value={securing}
                        onChange={(e) => setSecuring(e.target.value)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                      >
                        <option value="" disabled>
                          Select Securing
                        </option>
                        <option value="ALU CLAMP">ALU CLAMP</option>
                        <option value="HAND">HAND</option>
                      </select>
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Marine Superintendent Email
                      </label>
                      <input
                        type="marineSuperintendentEmail"
                        name="marineSuperintendentEmail"
                        id="marineSuperintendentEmail"
                        value={marineSuperintendentEmail}
                        onChange={(e) => setMarineSuperintendentEmail(e.target.value)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                      />
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Technical Superintendent Email
                      </label>
                      <input
                        type="technicalSuperintendentEmail"
                        name="technicalSuperintendentEmail"
                        id="technicalSuperintendentEmail"
                        value={technicalSuperintendentEmail}
                        onChange={(e) => setTechnicalSuperintendentEmail(e.target.value)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                      />
                    </div>
                    <div className="mt-5">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Technical Officer Email
                      </label>
                      <input
                        type="technicalOfficerEmail"
                        name="technicalOfficerEmail"
                        id="technicalOfficerEmail"
                        value={technicalOfficerEmail}
                        onChange={(e) => setTechnicalOfficerEmail(e.target.value)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-5">
                  {errorList.map
                    ? errorList.map((error) => (
                        <div key={error} className="text-red-500">
                          {error}
                        </div>
                      ))
                    : null}
                </div>

                <div className="mt-5 flex justify-end">
                  <button
                    type="button"
                    className="mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
                    onClick={() => setShowModal(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-fathom-blue hover:bg-fathom-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fathom-blue-dark"
                    onClick={clickSubmit}
                  >
                    {isUpdate ? "Save" : "Add"}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
          <ErrorDialog
            open={errorDialogOpen}
            onClose={() => setErrorDialogOpen(false)}
            title="Error"
            onConfirm={() => setErrorDialogOpen(false)}
          >
            {errorMessage}
          </ErrorDialog>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
