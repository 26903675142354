import React, { useState, useEffect } from "react";
import { forgot } from "../../services/strapiService";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import ErrorDialog from "../../components/Dialogs/ErrorDialog";
import ConfirmationToast from "../../components/ConfirmationToast/ConfirmationToast";

export default function Forgot() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [email, setEmail] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email) {
      setEmail(email);
    }
  }, []);

  const submitForgot = async () => {
    let forgotResult = await forgot(email);

    if (forgotResult.error) {
      setErrorDialogOpen(true);
    } else {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        navigate("/login");
      }, 2000);
    }
  };

  const goLogin = () => {
    navigate("/login");
  };

  return (
    <>
      <div className="min-h-screen flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="mx-auto w-auto"
                srcset="/images/logo_w500.png 2x /images/logo_w750.png 3x"
                src="/images/logo_w250.png"
                alt="Fathom Safety Logo"
              />
              <h2 className="mt-6 text-center text-3xl font-extrabold text-fathom-blue">
                Reset Password
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <div className="space-y-6">
                  <div>
                    <label
                      for="email"
                      className="block text-sm font-medium text-fathom-blue"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-fathom-blue hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                      onClick={submitForgot}
                    >
                      Reset Password
                    </button>
                  </div>
                  <div className="flex items-center text-sm text-fathom-blue cursor-pointer">
                    <span onClick={goLogin}>Return to Login</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/images/login_splash_w1920.jpg"
            alt=""
          />
        </div>
      </div>
      <ErrorDialog
        open={errorDialogOpen}
        onClose={() => setErrorDialogOpen(false)}
        title="Password Reset Error"
        onConfirm={() => setErrorDialogOpen(false)}
      >
        Error Resetting Password
      </ErrorDialog>
      {showToast && (
        <ConfirmationToast
          message="Your password reset email is on the way! "
          setShowToast={setShowToast}
        />
      )}
    </>
  );
}
