import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import ErrorDialog from "../../components/Dialogs/ErrorDialog";
import ClockIcon from "@heroicons/react/24/outline/ClockIcon";

export default function UseLadderModal({ showModal, setShowModal, ladderId }) {
  const cancelButtonRef = useRef(null);
  const queryClient = useQueryClient();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [dateTimeSubmit, setDateTimeSubmit] = useState("");
  const [port, setPort] = useState("");
  const [preInspectionReport, setPreInspectionReport] = useState(null);
  const [postInspectionReport, setPostInspectionReport] = useState(null);

  const handleDateTimeChange = (e) => {
    const value = e.target.value;
    setDateTimeSubmit(value);
    const [dateValue, timeValue] = value.split("T");
    setDate(dateValue);
    setTime(timeValue);
  };

  const handlePreInspectionReportUpload = (file) => {
    setPreInspectionReport(file);
  };

  const handlePostInspectionReportUpload = (file) => {
    setPostInspectionReport(file);
  };

  // Mutations
  const useLadderMutation = useMutation(
    () => {
      const formData = new FormData();

      formData.append("ladderId", ladderId);
      formData.append("submittedDate", dateTimeSubmit);
      formData.append("port", port);
      formData.append(
        `files.file1`,
        preInspectionReport,
        preInspectionReport.name
      );
      formData.append(
        `files.file2`,
        postInspectionReport,
        postInspectionReport.name
      );

      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/ladders/use`,
        formData,
        {
          headers: {
            Accept: "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["vessel"]);
      },
    }
  );

  const saveUseLadder = () => {
    setErrorList([]);
    let errors = [];

    if (date === "" || date === null || date === undefined) {
      errors.push("Date is required");
    }
    if (time === "" || time === null || time === undefined) {
      errors.push("Time is required");
    }
    if (port === "" || port === null || port === undefined) {
      errors.push("Port is required");
    }
    if (
      preInspectionReport === "" ||
      preInspectionReport === null ||
      preInspectionReport === undefined
    ) {
      errors.push("Pre-Inspection Report is required");
    }
    if (
      postInspectionReport === "" ||
      postInspectionReport === null ||
      postInspectionReport === undefined
    ) {
      errors.push("Post-Inspection Report is required");
    }

    if (errors.length > 0) {
      setErrorMessage(errors.join(", "));
      setErrorDialogOpen(true);
    } else {
      useLadderMutation.mutate();
      setShowModal(false);
    }
  };

  return (
    <>
      <div>
        <Transition.Root show={showModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={setShowModal}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-fathom-blue">
                      <ClockIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Use Ladder
                      </Dialog.Title>
                    </div>
                  </div>

                  <div className="mt-5">
                    <a
                      href="/Pilot_Ladder_Pre_Post_Inspection_Forms.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      <button
                        type="button"
                        className="inline-flex items-center mt-3 px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-3 h-3 mr-1"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                          />
                        </svg>
                        Download Inspection Forms
                      </button>
                    </a>
                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="dateTime"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Date/Time
                    </label>
                    <input
                      type="datetime-local"
                      name="dateTime"
                      id="dateTime"
                      value={`${date}T${time}`}
                      onChange={handleDateTimeChange}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                    />
                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="port"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Port
                    </label>
                    <input
                      type="text"
                      name="port"
                      id="port"
                      value={port}
                      onChange={(e) => setPort(e.target.value)}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                    />
                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="preInspectionReport"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Pre-Inspection Report
                    </label>
                    <input
                      type="file"
                      name="preInspectionReport"
                      id="preInspectionReport"
                      onChange={(e) =>
                        handlePreInspectionReportUpload(e.target.files[0])
                      }
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                    />
                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="postInspectionReport"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Post-Inspection Report
                    </label>
                    <input
                      type="file"
                      name="postInspectionReport"
                      id="postInspectionReport"
                      onChange={(e) =>
                        handlePostInspectionReportUpload(e.target.files[0])
                      }
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                    />
                  </div>

                  <div className="mt-5 flex justify-end">
                    <button
                      type="button"
                      className="mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
                      onClick={() => setShowModal(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-fathom-blue hover:bg-fathom-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fathom-blue-dark"
                      onClick={saveUseLadder}
                    >
                      Use Ladder
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
            <ErrorDialog
              open={errorDialogOpen}
              onClose={() => setErrorDialogOpen(false)}
              title="Error"
              onConfirm={() => setErrorDialogOpen(false)}
            >
              {errorMessage}
            </ErrorDialog>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
}
