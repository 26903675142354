import React from "react";
import { classNames } from "../../services/utils";

export default function ModulePagination({
  moduleStage,
  selectedQuestions,
  selectModuleStage,
  onFinish,
}) {
  return (
    <>
      <div className="flex justify-between flex-wrap pb-5 border-b border-gray-200">
        <div className="pb-5">
          <button
            type="button"
            disabled={moduleStage == 1 && true}
            className={classNames(
              moduleStage > 1
                ? "opacity-100"
                : "opacity-50 hover:bg-fathom-blue",
              "inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-fathom-blue hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
            )}
            onClick={() => {
              if (moduleStage > 1) {
                selectModuleStage(moduleStage - 1);
              }
            }}
          >
            Previous
          </button>
        </div>
        <div className="text-right sm:order-last">
          <button
            type="button"
            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-fathom-blue hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
            onClick={() => {
              if (moduleStage < selectedQuestions.length) {
                selectModuleStage(moduleStage + 1);
              } else {
                onFinish();
              }
            }}
          >
            {moduleStage < selectedQuestions.length ? "Next" : "Finish Test"}
          </button>
        </div>
        <div className="">
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            {moduleStage > 0 &&
              selectedQuestions.map((question, index) => {
                return (
                  <a
                    key={index}
                    href="#"
                    className={classNames(
                      moduleStage == index + 1
                        ? "bg-fathom-blue text-white"
                        : "bg-white text-gray-500",
                      "relative inline-flex items-center border border-gray-300 px-4 py-2 text-sm font-medium  hover:bg-fathom-dark-blue hover:text-white focus:z-20"
                    )}
                    onClick={() => selectModuleStage(index + 1)}
                  >
                    {index + 1}
                  </a>
                );
              })}
          </nav>
        </div>
      </div>
    </>
  );
}
