import React from "react";
import { classNames } from "../../services/utils";
import { useNavigate } from "react-router-dom";
import { Animated } from "react-animated-css";

export default function ModuleResult({ stateModuleUserData, restartModule }) {
  const navigate = useNavigate();

  const returnToModules = () => {
    navigate("/course");
  };

  const scorePercentage = stateModuleUserData.result && (
    (stateModuleUserData.result.correct / stateModuleUserData.result.total) *
    100
  ).toFixed(0);
  return (
    <>
    <Animated animationIn="pulse" isVisible={true}>
      <div className="flex items-center justify-center h-screen">
        <div className="bg-white overflow-hidden shadow align-middle p-10 sm:rounded-lg">
          <div className="px-4 py-3 text-center">
            <p className="text-xl">Your score was</p>
            <p
              className={classNames(
                stateModuleUserData.result && stateModuleUserData.result.result == "PASSED"
                  ? "text-green-600"
                  : "text-red-600",
                "text-6xl font-bold py-5"
              )}
            >
              {scorePercentage}%
            </p>
            <p className="pb-5">
              {stateModuleUserData.result && stateModuleUserData.result.result == "PASSED"
                ? "Congratulations, you have sucessly completed this module"
                : "Unfortunately, you have failed this module. You require a score of 75% to complete this module, please try again."}
            </p>
            <button
              type="button"
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
              onClick={
                stateModuleUserData.result && stateModuleUserData.result.result == "PASSED"
                  ? returnToModules
                  : restartModule
              }
            >
              {stateModuleUserData.result && stateModuleUserData.result.result == "PASSED"
                ? "Return to My Course"
                : "Restart Module"}
            </button>
            {}
          </div>
        </div>
      </div>
    </Animated>
    </>
  );
}
