import React, { Fragment } from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import Vimeo from "@u-wave/react-vimeo";

export default function VideoLibrary({ modules }) {
  return (
    <>
      <div className="relative pb-5 border-b border-gray-200 sm:pb-0">
        <PageHeader title="My Videos" />
        <div className="mt-4 mb-4">
          <div className="grid grid-cols-1 sm:grid-cols-3 -mx-2">
            {modules &&
              modules.map((module) => (
                <div key={module.module.id} className="px-2 mb-6">
                  <div className="bg-white shadow">
                    <div>
                      <Vimeo
                        video={module.module.VimeoUrl}
                        responsive
                        showPortrait={false}
                        showTitle={false}
                        showByline={false}
                      />
                    </div>
                    <div className="px-3 py-3">
                      <p className="truncate text-gray-800 text-left">
                        {module.module.ModuleName}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
