import Dialog from "./Dialog";
import DialogButton from "./DialogButton";
import AlertIcon from "./AlertIcon";

export default function ErrorDialog(props) {
  const { open, onClose, title, children, onConfirm } = props;
  if (!open) {
    return <></>;
  }

  return (
    <Dialog
      as="div"
      className="fixed z-10 inset-0 overflow-y-auto"
      open={open}
      onClose={onClose}
    >
      <div className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-500">
          <AlertIcon className="h-6 w-6" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {title}
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">{children}</p>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <DialogButton
            onClick={() => {
              onClose();
              onConfirm();
            }}
          >
            OK
          </DialogButton>
        </div>
      </div>
    </Dialog>
  );
}
