import React, { useState, useEffect } from "react";
import { login } from "../../services/strapiService";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import ErrorDialog from "../../components/Dialogs/ErrorDialog";

export default function Login() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email) {
      setEmail(email);
      setRememberMe(true);
    }
  }, []);

  const doLogin = async () => {
    if (rememberMe) {
      localStorage.setItem("email", email);
    } else {
      localStorage.removeItem("email");
    }

    let loginResult = await login(email, password);

    if (loginResult.error) {
      setErrorDialogOpen(true);
    } else {
      queryClient.invalidateQueries("user");
      navigate("/home", { state: { loginToken: loginResult.jwt } });
    }
  };

  const goForgotPass = () => {
    navigate("/forgot");
  };

  return (
    <>
      <div className="min-h-screen flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="mx-auto w-auto"
                srcSet="/images/logo_w500.png 2x /images/logo_w750.png 3x"
                src="/images/logo_w250.png"
                alt="Fathom Safety Logo"
              />
              <h2 className="mt-6 text-center text-3xl font-extrabold text-fathom-blue">
                Sign in to your account
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <div className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-fathom-blue"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-fathom-blue"
                    >
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 text-fathom-blue focus:ring-fathom-blue border-gray-300 rounded"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-fathom-dark-blue"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="text-sm">
                      <a
                        href="#"
                        onClick={goForgotPass}
                        className="font-medium text-fathom-red hover:text-fathom-dark-blue"
                      >
                        Forgot your password?
                      </a>
                    </div>
                  </div>

                  <div>
                    <button
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-fathom-blue hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fathom-blue"
                      onClick={doLogin}
                    >
                      Sign in
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/images/login_splash_w1920.jpg"
            alt=""
          />
        </div>
      </div>
      <ErrorDialog
        open={errorDialogOpen}
        onClose={() => setErrorDialogOpen(false)}
        title="Incorrect Login"
        onConfirm={() => setErrorDialogOpen(false)}
      >
        Sorry, your email or password was incorrect. <br />
        Please try again.
      </ErrorDialog>
    </>
  );
}
