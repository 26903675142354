import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";


export default function useSetupUser(setShowToast) {
  const queryClient = useQueryClient();

  return useMutation(
    ({setupUser}) => {
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/invites/accept`,
        setupUser,
        {
          headers: {
            Accept: "application/json"
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        //setShowToast(true);
      },
    }
  );
}
