import React, { useState, useContext } from "react";
import { classNames } from "../../services/utils";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PageHeader from "../../components/PageHeader/PageHeader";
import LadderHistoryTable from "./LadderHistoryTable";
import { CompanyContext } from "../../context/CompanyContext";
import { getVesselHistory } from "../../services/strapiService";
import ArrowUturnLeftIcon from "@heroicons/react/24/outline/ArrowUturnLeftIcon";

export default function LadderHistory({ companyType }) {
  const { id } = useParams();
  // const queryClient = useQueryClient();
  // const navigate = useNavigate();
  // const companyContext = useContext(CompanyContext);

  const data = useQuery(["history", id], () => getVesselHistory(id));

  return (
    <>
      <div className="relative pb-5 border-b border-gray-200 sm:pb-0">
        <div className="sm:flex sm:justify-between sm:items-baseline ">
          <PageHeader title="Ladder History" />
        </div>
        <span
          onClick={() => window.history.back()}
          className="hover:cursor-pointer text-fathom-blue hover:text-fathom-dark-blue text-sm flex items-center"
        >
          <ArrowUturnLeftIcon className="w-4 h-4 mr-1" />
          Back to Vessel
        </span>

        <div className="mt-4">
          <div className="mt-4 overflow-hidden">
            {data.data?.data && data.isSuccess && (
              <LadderHistoryTable data={data.data?.data} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
