import React, { useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import getFile from "../../components/GetFile/GetFile";

const LadderHistoryTable = ({ data }) => {
  const columns = [
    {
      name: "Date/Time",
      selector: (row) => {
        const submittedDate = new Date(row.submittedDate);
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        };
        const formattedDateTime = submittedDate.toLocaleString(
          "en-GB",
          options
        );
        return formattedDateTime;
      },
      sortable: true,
    },
    {
      name: "Ladder Serial No",
      selector: (row) => `${row.ladderSerial}`,
      sortable: true,
    },
    {
      name: "Port",
      selector: (row) => row.port,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      label: "File 1",
      name: "File 1",
      selector: (row) => row.file1,
      cell: (row) =>
        row.file1 && (
          <a
            href={getFile({ file: row.file1 })}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download File
          </a>
        ),
      sortable: true,
    },
    {
      label: "File 2",
      name: "File 2",
      selector: (row) => row.file2,
      cell: (row) =>
        row.file2 && (
          <a
            href={getFile({ file: row.file2 })}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download File
          </a>
        ),
      sortable: true,
    },
  ];

  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = useMemo(() => {
    if (!searchTerm) {
      return data;
    }

    return data.filter((item) => {
      return Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }, [data, searchTerm]);

  return (
    <div className="container mx-auto">
      <div className="mb-3">
        <input
          type="text"
          className="form-control ml-auto shadow-sm focus:ring-fathom-blue focus:border-fathom-blue block sm:text-sm border-gray-300 rounded-md"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        responsive
        striped
        hover
        condensed
      />
    </div>
  );
};

export default LadderHistoryTable;
