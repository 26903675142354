import React from "react";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../services/utils";

export default function NavigationItem({ name, Icon, isCurrent, onClicked }) {
  const navigate = useNavigate();

  return (
    <a
      key={name}
      href={"#"}
      className={classNames(
        isCurrent
          ? "bg-fathom-blue text-white"
          : "text-gray-500 bg-white hover:bg-fathom-blue hover:text-white",
        "group flex items-center px-2 py-2 text-base font-medium rounded-md"
      )}
      onClick={onClicked}
    >
      {Icon && (
        <Icon
          className={classNames(
            isCurrent ? "text-gray-00" : "text-gray-400 group-hover:text-white",
            "mr-4 flex-shrink-0 h-6 w-6"
          )}
          aria-hidden="true"
        />
      )}
      {name}
    </a>
  );
}
