import React from "react";
import { classNames } from "../../services/utils";
import './simplequestion.css';

export default function SimpleQuestion({ question, questionNo, answerQuestion, answered }) {

  const qLetters = ['A', 'B', 'C', 'D'];

  const [selectedAnswer, setSelectedAnswer] = React.useState(null);
  const [isCorrect, setIsCorrect] = React.useState(false);
  const [correctedAnswer, setCorrectedAnswer] = React.useState(false);

  React.useEffect(() => {
    if(answered) {
      setSelectedAnswer(answered);
      if(isAnswerCorrect(answered)) {
        setIsCorrect(true);
      }
      else {
        setIsCorrect(false);
        setTimeout(() => {
          setCorrectedAnswer(getCorrectAnswerId());
          }, 700);
      }

    }
    else {
      setSelectedAnswer(null);
    }
  }, [answered])

  const isAnswerCorrect = (answeredId) => {
    let answerInQuestion = question.attributes.answers.find((answer) => answer.id == answeredId)
    return answerInQuestion.isCorrect;
  }

  const getCorrectAnswerId = () => {
    return question.attributes.answers.find((answer) => answer.isCorrect == true).id;
  }

  const clickAnswer = (id, isCorrect) => {
    if(selectedAnswer == null) {
    setSelectedAnswer(id);
    answerQuestion(question.id, id);
    setIsCorrect(isCorrect)
    if(!isCorrect) {
      setTimeout(() => {
      setCorrectedAnswer(getCorrectAnswerId());
      }, 700);
    }
    }
  };

  return (

    <div className="block my-10 p-6 bg-white border border-gray-200 rounded-lg shadow-md">
      <h2 className="font-bold pb-6">{questionNo + ")"} {question.attributes.questiontext}</h2>
      <fieldset>
        <div className="space-y-4">
          {question.attributes.answers.map((answer, index) => {
            return (

              <label key={index} className={classNames('relative block border rounded-lg shadow-sm p-6 sm:flex sm:justify-between focus:outline-none cursor-pointer',
                selectedAnswer == answer.id ? '' : 'border-gray-300',
                (selectedAnswer == answer.id && !isCorrect) ? 'wrong border-red-400 ring-2 ring-red-400':' ',  //Wrong Answer Format
                (selectedAnswer == answer.id && isCorrect) ? ' border-green-400 ring-2 ring-green-400':' ', //Correct Answer Format
                (correctedAnswer == answer.id) ? ' border-green-400 ring-2 ring-green-400':' ') //CorrectED Answer Format
                }>

                <input
                  id={"answer" + answer.id}
                  type="radio"
                  name="answer"
                  value={"answer" + answer.id}
                  className="sr-only"
                  aria-labelledby={"answer-" + answer.id + "-label"}
                  aria-describedby={"answer-" + answer.id + "-description-" + answer.id}
                  onClick={() => clickAnswer(answer.id, answer.isCorrect)}
                />
                <div className="flex items-center">
                  <div className="text-sm">
                    <p id="answer-0-label" className="font-medium text-gray-900">
                      <span className="text-xl pr-4">{qLetters[index]}</span> {answer.Answer}
                    </p>
                  </div>
                </div>
                <div id="server-size-0-description-1" className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                  <div>
                    {selectedAnswer == answer.id && isCorrect && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" className="w-6 h-6 stroke-green-400"><path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>}
                    {selectedAnswer == answer.id && !isCorrect && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" className="w-6 h-6 stroke-red-400"><path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>}
                    {correctedAnswer == answer.id && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" className="w-6 h-6 stroke-green-400"><path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>}
                  </div>
                </div>
                <div className="absolute -inset-px rounded-lg border-2 pointer-events-none" aria-hidden="true"></div>
              </label>

            );
          })}
        </div>
      </fieldset>
    </div>
  );
}