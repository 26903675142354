import React, { useState } from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";


export default function Settings({ userLoggedIn, displaySomeToast }) {
  const [renewalReminderDaysBeforeExpiry, setRenewalReminderDaysBeforeExpiry] =
    useState(userLoggedIn.company.userRenewalReminderIntervalDays);
  const [trainingReminderDays, setTrainingReminderDays] = useState(userLoggedIn.company.userTrainingReminderIntervalDays);
  const queryClient = useQueryClient();

   // Mutations
   const saveSettingsMutation = useMutation(
    () => {
      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/company/settings`,
        {
          userTrainingReminderIntervalDays: trainingReminderDays, 
          userRenewalReminderIntervalDays: renewalReminderDaysBeforeExpiry,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["user"]);
        displaySomeToast("Settings saved", "success");
      },
    }
  );

  const handleSave = () => {
    saveSettingsMutation.mutate();
  };

  const handleRenewalReminderChange = (e) => {
    setRenewalReminderDaysBeforeExpiry(parseInt(e.target.value));
  };

  const handleNotCompleteReminderChange = (e) => {
    setTrainingReminderDays(parseInt(e.target.value));
  };

  return (
    <>
      <div className="relative pb-5 border-b border-gray-200 sm:pb-0">
        <PageHeader title="Company Settings" />
        <div className="mt-4">
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="bg-white px-4 py-5 sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6">
                  <h1 className={"font-bold text-lg"}>Email Settings</h1>
                </div>
                <div className="col-span-6 sm:col-span-2">
                  {" "}
                  <label
                    htmlFor="trainingReminderDays"
                    className="block text-sm font-medium text-gray-700 "
                  >
                    Remind User Email if not completed training in{" "}
                    <strong>{trainingReminderDays}</strong> days
                  </label>
                  <input
                    type="number"
                    name="trainingReminderDays"
                    id="trainingReminderDays"
                    value={trainingReminderDays}
                    onChange={handleNotCompleteReminderChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                  />
                </div>
                <div className="col-span-6 sm:col-span-2">
                  {" "}
                  <label
                    htmlFor="renewalReminderDaysBeforeExpiry"
                    className="block text-sm font-medium text-gray-700 "
                  >
                    Renewal Reminder Email sent{" "}
                    <strong>{renewalReminderDaysBeforeExpiry}</strong> days
                    before expiry
                  </label>
                  <input
                    type="number"
                    name="renewalReminderDaysBeforeExpiry"
                    id="renewalReminderDaysBeforeExpiry"
                    value={renewalReminderDaysBeforeExpiry}
                    onChange={handleRenewalReminderChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between bg-gray-50 px-4 py-3 text-right sm:px-6">
              <button
                onClick={handleSave}
                className="inline-flex justify-center rounded-md border border-transparent bg-fathom-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-fathom-blue focus:ring-offset-2"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
