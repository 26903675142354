export default function DialogButton(props) {
  const { type = "button", children, onClick, className = "" } = props;
  return (
    <button
      className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-fathom-blue text-base font-medium text-white hover:bg-fathom-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fathom-blue sm:col-start-2 sm:text-sm ${className}`}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
