export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export function convertArrayOfObjectsToCSV(array, fields) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = fields;

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      if (item[key]?.toString().indexOf("Object") > -1) {
        if(key=='rank' || key == 'vessel') {
          result += item[key]?.name;
        }
      } else {
        if(item[key])
        result += item[key].toString();
      else
        result += ' ';
      }

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

export function downloadCSV(array, fields, filename) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array, fields);
  if (csv == null) return;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}
