import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import getInviteByCode from "../../data/queries/getInviteByCode";
import { useParams, useSearchParams, Navigate } from "react-router-dom";
import useSetupUser from "../../data/hooks/useSetupUser";
import ErrorDialog from "../../components/Dialogs/ErrorDialog";
import ConfirmationToast from "../../components/ConfirmationToast/ConfirmationToast";
import axios from "axios";
import getImage from "../../components/GetImage/GetImage";
import * as Sentry from "@sentry/react";

export default function AcceptInvitation() {
  const [searchParams, setSearchParams] = useSearchParams();
  let { id } = useParams();
  if (id == undefined) {
    id = searchParams.get("inviteId");
  }

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordConfirm, setPasswordConfirm] = useState(null);
  const [boardingArrangementId, setBoardingArrangementId] = useState(null);
  const [jobRoleId, setJobRoleId] = useState(null);
  const [regionId, setRegionId] = useState(null);
  const [rankId, setRankId] = useState(null);
  const [terms, setTerms] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const setupUserMutation = useSetupUser();

  const ranks = useQuery(["ranks"], () => {
    return axios.get(`${process.env.REACT_APP_API_ROOT}/ranks`);
  });

  const { data: inviteData, status: inviteStatus } = useQuery(
    ["invite", id],
    () => getInviteByCode(id)
  );

  const onAccept = (e) => {
    e.preventDefault();
    if (password != passwordConfirm) {
      setErrorMessage("The passwords entered do not match, please try again.");
      setErrorDialogOpen(true);
      return;
    }
    if (password.length < 6) {
      setErrorMessage(
        "Your password is too short, it must be a minimum of 6 characters, please try again."
      );
      setErrorDialogOpen(true);
      return;
    }
    if (!terms) {
      setErrorMessage(
        "You must accept the terms and conditions before accepting the invitation."
      );
      setErrorDialogOpen(true);
      return;
    }
    if (!jobRoleId || !boardingArrangementId) {
      setErrorMessage(
        "You must select your Job Role and Boarding Arrangement before accepting the invitation."
      );
      setErrorDialogOpen(true);
      return;
    }

    if (inviteData.data.company.regions.lenght>0 && !regionId) {
      setErrorMessage(
        "You must select Region before accepting the invitation."
      );
      setErrorDialogOpen(true);
      return;
    }
     

    

    const setupUser = {
      firstName,
      lastName,
      password,
      boardingArrangementId,
      jobRoleId,
      inviteId: id,
      rankId,
      userLevel: 1,
    };

    if (inviteData.data.company.regions.length>0) {
      setupUser.regionId = regionId
    }

    setupUserMutation.mutate(
      { setupUser },
      {
        onSuccess: () => {
          //setShowToast(true);
        },
      }
    );
  };

  setupUserMutation.isSuccess && window.location.replace("/login");

  // Handle Form Error
  useEffect(() => {
    if (setupUserMutation.isError) {
      Sentry.captureException(setupUserMutation.error)
      setErrorMessage(
        "There's been an error adding you to the system. Please contact your manager for more information."
      );
      setErrorDialogOpen(true);
    }
  }, [setupUserMutation.isError]);

  return inviteData && inviteData.data.redirect === true ? (
    <Navigate to="/" replace={true} />
  ) : (
    <section className="bg-gray-5">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto">
        <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900"
        >
          <img
            className="mx-auto w-auto"
            srcSet="/images/logo_w500.png 2x /images/logo_w750.png 3x"
            src="/images/logo_w250.png"
            alt="Fathom Safety Logo"
          />
        </a>
        <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-3xl xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2x">
              Accept invitation to join{" "}
              {inviteData ? inviteData.data.company.CompanyName : ""}
            </h1>
            <form className="space-y-4 md:space-y-6" action="#">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="firstName"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    autoComplete="given-name"
                    className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-fathom-blue focus:border-fathom-blue block w-full p-2.5"
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="lastName"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    autoComplete="family-name"
                    className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-fathom-blue focus:border-fathom-blue block w-full p-2.5"
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-fathom-blue focus:border-fathom-blue block w-full p-2.5"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    minLength={6}
                    aria-describedby="password-requirements"
                  />
                  <p
                    className="mt-2 text-xs text-gray-600"
                    id="password-requirements"
                  >
                    Your password must be at least 6 characters.
                  </p>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="passwordConfirm"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Confirm password
                  </label>
                  <input
                    type="password"
                    name="passwordConfirm"
                    id="passwordConfirm"
                    className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-fathom-blue focus:border-fathom-blue block w-full p-2.5"
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    required
                    minLength={6}
                  />
                </div>
                <div className="col-span-6">
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Select Role
                  </label>

                  <ul className="grid w-full gap-6 md:grid-cols-3">
                    {inviteData &&
                      inviteData.data.company.user_roles.map((jobRole) => (
                        <li>
                          <input
                            type="radio"
                            id={"role" + jobRole.id}
                            name="role"
                            value={jobRole.id}
                            onChange={(e) => setJobRoleId(e.target.value)}
                            className="hidden peer"
                            required
                          />
                          <label
                            htmlFor={"role" + jobRole.id}
                            className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-fathom-blue peer-checked:text-fathom-blue hover:text-gray-600 hover:bg-gray-100"
                          >
                            <div className="block">
                              <div className="w-full">{jobRole.RoleName}</div>
                            </div>

                            {jobRole.icon != null && (
                              <img
                                src={getImage({
                                  image: jobRole.icon,
                                })}
                                className="w-20"
                                alt={jobRole.RoleName}
                              />
                            )}
                          </label>
                        </li>
                      ))}
                  </ul>
                </div>

                {inviteData && inviteData.data.company.companyType === 'trainingAndManagement' && 
                <div className="col-span-6">
                  <label
                    htmlFor="rank"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Select Rank
                  </label>

                  <ul className="grid w-full gap-6 md:grid-cols-3">
                    {ranks?.data?.data?.data &&
                      ranks?.data?.data?.data.map((rank) => (
                        <li>
                          <input
                            type="radio"
                            id={"rank" + rank.id}
                            name="rank"
                            value={rank.id}
                            onChange={(e) => setRankId(e.target.value)}
                            className="hidden peer"
                            required
                          />
                          <label
                            htmlFor={"rank" + rank.id}
                            className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-fathom-blue peer-checked:text-fathom-blue hover:text-gray-600 hover:bg-gray-100"
                          >
                            <div className="block">
                              <div className="w-full">
                                {rank.attributes.name}
                              </div>
                            </div>
                          </label>
                        </li>
                      ))}
                  </ul>
                </div> 
                }
                <div className="col-span-6">
                  <label
                    htmlFor="boardingArrangement"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Select Boarding Arrangement
                  </label>

                  <ul className="grid w-full gap-6 md:grid-cols-3">
                    {inviteData &&
                      inviteData.data.company.user_boarding_types.map(
                        (boardingArrangement) => (
                          <li>
                            <input
                              type="radio"
                              id={"boarding" + boardingArrangement.id}
                              name="boardingArrangement"
                              value={boardingArrangement.id}
                              onChange={(e) =>
                                setBoardingArrangementId(e.target.value)
                              }
                              className="hidden peer"
                              required
                            />
                            <label
                              htmlFor={"boarding" + boardingArrangement.id}
                              className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-fathom-blue peer-checked:text-fathom-blue hover:text-gray-600 hover:bg-gray-100"
                            >
                              <div className="block">
                                <div className="w-full">
                                  {boardingArrangement.BoardingTypeName}
                                </div>
                              </div>

                              {boardingArrangement.icon != null && (
                                <img
                                  src={getImage({
                                    image: boardingArrangement.icon,
                                  })}
                                  className="w-20"
                                  alt={boardingArrangement.BoardingTypeName}
                                />
                              )}
                            </label>
                          </li>
                        )
                      )}
                  </ul>
                </div>
                {inviteData && inviteData.data.company.regions && inviteData.data.company.regions.length > 0 && (
                  <div className="col-span-6">
                    <label
                      htmlFor="region"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Select Region
                    </label>
                    <select
                      id="region"
                      name="region"
                      onChange={(e) => setRegionId(e.target.value)}
                      className="block w-1/2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:border-fathom-blue focus:ring focus:ring-fathom-blue focus:ring-opacity-50"
                      required
                    >
                      <option >Select a region</option>
                      {inviteData.data.company.regions.map((region) => (
                        <option key={region.id} value={region.id}>
                          {region.Title}
                        </option>
                      ))}
                    </select>
                  </div>
                )}  

                <div className="col-span-6">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="terms"
                        aria-describedby="terms"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded focus:ring-3 text-fathom-blue"
                        required=""
                        value={terms}
                        onChange={(e) => setTerms(e.target.value)}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="terms"
                        className="font-light text-gray-500"
                      >
                        I accept the{" "}
                        <a
                          className="font-medium text-fathom-blue hover:underline "
                          href="https://www.fathomsafety.com/terms"
                          target="_blank"
                        >
                          Terms and Conditions
                        </a>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="w-full text-white bg-fathom-blue hover:bg-fathom-dark-blue focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                onClick={onAccept}
              >
                Accept Invitation
              </button>
            </form>
          </div>
        </div>
      </div>
      <ErrorDialog
        open={errorDialogOpen}
        onClose={() => setErrorDialogOpen(false)}
        title="Error"
        onConfirm={() => setErrorDialogOpen(false)}
      >
        {errorMessage}
      </ErrorDialog>
      {showToast && (
        <ConfirmationToast
          message="You have been successfully invited to the system. Please login with your credentials."
          setShowToast={setShowToast}
        />
      )}
    </section>
  );
}
