import React, { Component } from "react";
import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="h-screen flex flex-col justify-center items-center">
          <div className="px-24 py-24 flex justify-center items-center gap-28">
            <div className="w-1/2 relative pb-12 lg:pb-0">
              <h1 className="my-4 text-gray-800 font-bold text-4xl">
                Ooops! Something went wrong.
              </h1>
              <p className="my-4 text-gray-800">
                Sorry about that! Let's try and take you back to the dashboard.
              </p>
              {this.state.error && (
                <div>
                  <p>{this.state.error.toString()}</p>
                  <details
                    style={{ whiteSpace: "pre-wrap" }}
                    className="py-4 hover:cursor-pointer"
                  >
                    {this.state.errorInfo.componentStack}
                  </details>
                </div>
              )}
              <a
                href="/home"
                className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-fathom-blue text-base font-medium text-white hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fathom-blue sm:col-start-2 sm:text-sm"
              >
                Go to Dashboard
              </a>
            </div>
            <div>
              <ExclamationTriangleIcon
                className="h-40 w-40 text-fathom-blue"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
