import React, { useState, useEffect } from "react";
import { resetpassword } from "../../services/strapiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import ErrorDialog from "../../components/Dialogs/ErrorDialog";
import ConfirmationToast from "../../components/ConfirmationToast/ConfirmationToast";

export default function ResetPassword() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const submitReset = async () => {
    let resetResult = await resetpassword(
      searchParams.get("token"),
      newPassword1,
      newPassword2
    );

    if (resetResult.error) {
      setErrorDialogOpen(true);
    } else {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        navigate("/login");
      }, 2000);
    }
  };

  return (
    <>
      <div className="min-h-screen flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="mx-auto w-auto"
                srcset="/images/logo_w500.png 2x /images/logo_w750.png 3x"
                src="/images/logo_w250.png"
                alt="Fathom Safety Logo"
              />
              <h2 className="mt-6 text-center text-3xl font-extrabold text-fathom-blue">
                Please enter your new password
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <div className="space-y-6">
                  <div>
                    <label
                      for="email"
                      className="block text-sm font-medium text-fathom-blue"
                    >
                      {" "}
                      Password{" "}
                    </label>
                    <div className="mt-1">
                      <input
                        id="password1"
                        value={newPassword1}
                        onChange={(e) => setNewPassword1(e.target.value)}
                        name="password1"
                        type="password"
                        minLength={6}
                        aria-describedby="password-requirements"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm"
                      />
                    </div>
                    <p
                      className="mt-2 text-xs text-gray-600"
                      id="password-requirements"
                    >
                      Your password must be at least 6 characters.
                    </p>
                  </div>

                  <div>
                    <label
                      for="password2"
                      className="block text-sm font-medium text-fathom-blue"
                    >
                      {" "}
                      Password Confirmation{" "}
                    </label>
                    <div className="mt-1">
                      <input
                        id="password2"
                        value={newPassword2}
                        onChange={(e) => setNewPassword2(e.target.value)}
                        name="password2"
                        type="password"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <button
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-fathom-blue hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                      onClick={submitReset}
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/images/login_splash_w1920.jpg"
            alt=""
          />
        </div>
      </div>
      <ErrorDialog
        open={errorDialogOpen}
        onClose={() => setErrorDialogOpen(false)}
        title="Password Reset Error"
        onConfirm={() => setErrorDialogOpen(false)}
      >
        Error Resetting Password. Please ensure your password has a minimum of 6
        characters.
      </ErrorDialog>
      {showToast && (
        <ConfirmationToast
          message="Password Updated Sucessfully"
          setShowToast={setShowToast}
        />
      )}
    </>
  );
}
