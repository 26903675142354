import React from "react";

const currentDate = new Date();

const addMonths = (date, months) => {
  var result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return result;
};

const calculateCircleColor = (manufacturerDate, maxLife, type) => {
  const expiryDate = addMonths(new Date(manufacturerDate), maxLife);
  const timeDiff = expiryDate.getTime() - currentDate.getTime();
  const diffMonths = Math.floor(timeDiff / (1000 * 3600 * 24 * 30));

  let circleColor = "white";

  if (manufacturerDate) {
    if (diffMonths < 0) {
      circleColor = "red";
    } else if (type === "securingRopes" || type === "manropes") {
      if (diffMonths <= 2) {
        circleColor = "yellow";
      } else {
        circleColor = "green";
      }
    } else if (type === "accommodationLadderFalls") {
      if (diffMonths <= 6) {
        circleColor = "yellow";
      } else {
        circleColor = "green";
      }
    } else if (type === "embarkationLadders") {
      if (diffMonths <= 4) {
        circleColor = "yellow";
      } else {
        circleColor = "green";
      }
    }
  }

  return circleColor;
};

const Ancillary = ({ type, maxLife, showViewAncillary, ancillaryData }) => {
  // Ensure the correct number of items for each type
  let ancillaryItems = [];
  switch (type) {
    case "securingRopes":
      ancillaryItems = ancillaryData?.data[type]?.concat(
        new Array(4 - ancillaryData?.data[type]?.length).fill({}) // Fill with empty objects for missing items
      );
      break;
    case "manropes":
      ancillaryItems = ancillaryData?.data[type]?.concat(
        new Array(2 - ancillaryData?.data[type]?.length).fill({})
      );
      break;
    case "accommodationLadderFalls":
      ancillaryItems = ancillaryData?.data[type]?.concat(
        new Array(2 - ancillaryData?.data[type]?.length).fill({})
      );
      break;
    case "embarkationLadders":
      ancillaryItems = ancillaryData?.data[type]?.concat(
        new Array(6 - ancillaryData?.data[type]?.length).fill({})
      );
      break;
    default:
      break;
  }

  return ancillaryItems?.map((item, index) => (
    <div
      key={item.id || `placeholder-${index}`} // Use index for placeholder keys
      className="flex flex-col items-center justify-center cursor-pointer"
      onClick={() => showViewAncillary(type, index, item)}
    >
      {type === "embarkationLadders" && item.id ? (
        <>
          <h3 className="text-lg">Ladder {index + 1}</h3>
          <div
            className={`w-16 h-16 bg-${calculateCircleColor(
              item.manufactureDate,
              maxLife,
              type
            )}-500 rounded-full border border-gray-300 relative flex items-center justify-center`}
          >
            <span className="text-white text-2xl font-bold">
              {item.manufactureDate && (
                <span>
                  {Math.floor(
                    (currentDate.getTime() -
                      new Date(item.manufactureDate).getTime()) /
                      (1000 * 3600 * 24 * 30)
                  )}
                </span>
              )}
            </span>
          </div>
        </>
      ) : (
        <div
          className={`w-8 h-8 bg-${calculateCircleColor(
            item.manufactureDate,
            maxLife,
            type
          )}-500 rounded-full border border-gray-300 mx-1 flex items-center justify-center`}
        >
          <span className="text-white text-lg font-bold">
            {item.manufactureDate && (
              <span>
                {Math.floor(
                  (currentDate.getTime() -
                    new Date(item.manufactureDate).getTime()) /
                    (1000 * 3600 * 24 * 30)
                )}
              </span>
            )}
          </span>
        </div>
      )}
    </div>
  ));
};

export default Ancillary;
