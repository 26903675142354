import React from "react";

const getImage = ({ image, desiredFormat = "thumbnail" }) => {
  const getImageUrl = () => {
    if (image && image.url && image.url.startsWith("https://")) {
      // Image is hosted on S3
      const format =
        desiredFormat && image.formats[desiredFormat]
          ? desiredFormat
          : Object.keys(image.formats)[0];
      return image.formats[format].url;
    } else if (image && image.formats) {
      // Image is hosted locally
      const format =
        desiredFormat && image.formats[desiredFormat]
          ? desiredFormat
          : Object.keys(image.formats)[0];
      return process.env.REACT_APP_MEDIA_ROOT + image.formats[format].url;
    } else {
      // No image found
      return null;
    }
  };

  const imageUrl = getImageUrl();
  const noImageFoundUrl = "/images/vessel_blank.jpg";

  return imageUrl || noImageFoundUrl;
};

export default getImage;
