import React, { useState, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { CompanyContext } from "../../context/CompanyContext";
import { downloadCSV } from "../../services/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";

const MyFleetTable = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        label: "Name",
        name: "Name",
        selector: (row) => row.name,
        cell: (row) => <Link to={`/vessel/${row.id}`}>{row.name}</Link>,
        sortable: true,
      },
      {
        label: "Pilot Ladders",
        name: "Pilot Ladders",
        selector: (row) =>
          calculateLadderAgePercentage([
            row.ladder1,
            row.ladder2,
            row.ladder3,
          ]) + "%",
        sortable: true,
      },
      {
        label: "Embarkation Ladders",
        name: "Embarkation Ladders",
        selector: (row) =>
          calculateEquipmentAgePercentage(
            row.ancillary_equipments,
            "embarkationLadders"
          ) + "%",
        sortable: true,
      },
      {
        label: "Manropes",
        name: "Manropes",
        selector: (row) =>
          calculateEquipmentAgePercentage(
            row.ancillary_equipments,
            "manropes"
          ) + "%",
        sortable: true,
      },
      {
        label: "Securing Ropes",
        name: "Securing Ropes",
        selector: (row) =>
          calculateEquipmentAgePercentage(
            row.ancillary_equipments,
            "securingRopes"
          ) + "%",
        sortable: true,
      },
      {
        label: "Accommodation Falls",
        name: "Accomodation Falls",
        selector: (row) =>
          calculateEquipmentAgePercentage(
            row.ancillary_equipments,
            "accommodationLadderFalls"
          ) + "%",
        sortable: true,
      },
      {
        label: "Overall Health",
        name: "Overall Health",
        selector: (row) => calculateOverallHealthPercentage(row) + "%",
        sortable: true,
      },
    ],
    []
  );

  const company = useContext(CompanyContext);
  const calculateLadderAgePercentage = (data) => {
    const currentDate = new Date();
    let totalMonths = 0;
    let count = 0;

    data.forEach((ladder) => {
      if (ladder !== null && ladder !== undefined) {
        const ageInMonths = Math.ceil(
          (currentDate - new Date(ladder.manufactureDate)) /
            (1000 * 60 * 60 * 24 * 30)
        );
        totalMonths += ageInMonths;
        count++;
      }
    });
    const averageAgeInMonths = count === 0 ? 0 : totalMonths / count;
    const maxLife = company.maxLadderLife;

    const healthPercentage = Math.min(
      100,
      Math.max(0, 100 - (averageAgeInMonths / maxLife) * 100)
    );

    return Math.round(healthPercentage);
  };
  const calculateEquipmentAgePercentage = (row, equipmentType) => {
    const currentDate = new Date();
    let totalMonths = 0;
    let count = 0;

    row.forEach((equipment) => {
      if (equipment.type === equipmentType) {
        const ageInMonths = Math.ceil(
          (currentDate - new Date(equipment.manufactureDate)) /
            (1000 * 60 * 60 * 24 * 30)
        );
        totalMonths += ageInMonths;
        count++;
      }
    });
    const averageAgeInMonths = count === 0 ? 0 : totalMonths / count;
    let modifiedEquipmentType = equipmentType;
    if (equipmentType === "embarkationLadders") {
      modifiedEquipmentType = "embarkation";
    } else if (equipmentType === "accommodationLadderFalls") {
      modifiedEquipmentType = "accomLdrFalls";
    }
    const maxLifeName =
      "max" +
      modifiedEquipmentType.charAt(0).toUpperCase() +
      modifiedEquipmentType.slice(1) +
      "Life";
    const maxLife = company[maxLifeName];

    const healthPercentage = Math.min(
      100,
      Math.max(0, 100 - (averageAgeInMonths / maxLife) * 100)
    );

    return Math.round(healthPercentage);
  };

  const calculateOverallHealthPercentage = (row) => {
    const pilotLaddersPercentage = calculateLadderAgePercentage([
      row.ladder1,
      row.ladder2,
      row.ladder3,
    ]);
    const embarkationPercentage = calculateEquipmentAgePercentage(
      row.ancillary_equipments,
      "embarkationLadders"
    );
    const manropesPercentage = calculateEquipmentAgePercentage(
      row.ancillary_equipments,
      "manropes"
    );
    const securingRopesPercentage = calculateEquipmentAgePercentage(
      row.ancillary_equipments,
      "securingRopes"
    );
    const accommodationFallsPercentage = calculateEquipmentAgePercentage(
      row.ancillary_equipments,
      "accommodationLadderFalls"
    );

    // Define weights for each equipment type
    const weights = {
      pilotLadders: 0.4,
      embarkationLadders: 0.2,
      manropes: 0.25,
      securingRopes: 0.1,
      accommodationFalls: 0.05,
    };

    // Calculate weighted scores
    const weightedScores = {
      pilotLadders: pilotLaddersPercentage * weights.pilotLadders,
      embarkationLadders: embarkationPercentage * weights.embarkationLadders,
      manropes: manropesPercentage * weights.manropes,
      securingRopes: securingRopesPercentage * weights.securingRopes,
      accommodationFalls:
        accommodationFallsPercentage * weights.accommodationFalls,
    };

    // Sum up weighted scores
    const sumOfWeightedScores = Object.values(weightedScores).reduce(
      (acc, score) => acc + score,
      0
    );

    // Calculate overall health rating by dividing the sum of weighted scores by the total weight
    const totalWeight = Object.values(weights).reduce(
      (acc, weight) => acc + weight,
      0
    );
    const overallHealthRating = sumOfWeightedScores / totalWeight;

    return Math.round(overallHealthRating);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = useMemo(() => {
    if (!searchTerm) {
      return data;
    }

    return data.filter((item) => {
      return Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }, [data, searchTerm]);

  const Export = ({ onExport, filename }) => (
    <button
      type="button"
      className="inline-flex items-center mt-2 px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-fathom-blue hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
      onClick={() => onExport(filename)}
    >
      <FontAwesomeIcon icon={faFileCsv} className="h-4 w-4 pr-2 text-white" />
      Export
    </button>
  );

  const actionsMemo = React.useMemo(() => {
    const fileName = "fleet_export.csv";
    return (
      <Export
        filename={fileName}
        onExport={() =>
          downloadCSV(
            filteredData,
            [
              "name",
              "pilotLadders",
              "embarkationLadders",
              "manropes",
              "securingRopes",
              "accommodationFalls",
              "overallHealth",
            ],
            fileName
          )
        }
      />
    );
  }, [filteredData]);

  return (
    <div className="container mx-auto">
      <div className="mb-3">
        <input
          type="text"
          className="form-control ml-auto shadow-sm focus:ring-fathom-blue focus:border-fathom-blue block sm:text-sm border-gray-300 rounded-md"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        responsive
        striped
        hover
        condensed
        actions={actionsMemo}
      />
    </div>
  );
};

export default MyFleetTable;
