import React from 'react'

export default function Welcome() {
  return (
    <>
    <h2>Welcome to LADRZ</h2>
    <p>This is a basic welcome page.</p>
    <p><a href='/login'>Login</a></p>
    </>
  )
}