import { Fragment, useEffect, useRef, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import getFile from "../../components/GetFile/GetFile";
import ErrorDialog from "../../components/Dialogs/ErrorDialog";
import DeleteAncillaryModal from "./DeleteAncillaryModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnchor } from "@fortawesome/free-solid-svg-icons";
import { CompanyContext } from "../../context/CompanyContext";

export default function ViewAncillaryModal({
  showModal,
  setShowModal,
  vesselId,
  ancillaryType,
  ancillaryItemIndex,
  ancillaryItemEdit,
}) {
  const company = useContext(CompanyContext);
  const cancelButtonRef = useRef(null);
  const queryClient = useQueryClient();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [serialNumber, setSerialNumber] = useState("");
  const [manufactureDate, setManufactureDate] = useState("");
  const [type, setType] = useState("");
  const [certificateFile, setCertificateFile] = useState(null);
  const itemId = ancillaryItemEdit?.id;
  const [showDeleteAncillaryModal, setShowDeleteAncillaryModal] =
    useState(false);

  useEffect(() => {
    if (ancillaryItemEdit) {
      setSerialNumber(ancillaryItemEdit.serialNumber);
      setManufactureDate(ancillaryItemEdit.manufactureDate);
      setType(ancillaryItemEdit.type);
    }
  }, [ancillaryItemEdit]);

  // Format Type Name
  const formatTypeName = (name) => {
    let capitalized = name.charAt(0).toUpperCase() + name.slice(1);
    let formattedName = capitalized.replace(/([A-Z])/g, " $1");
    if (formattedName.endsWith("s")) {
      formattedName = formattedName.slice(0, -1);
    }
    return formattedName;
  };

  const getAncillaryCertificate = async () => {
    const fileUrl = getFile({ file: ancillaryItemEdit?.certificate });
    window.open(fileUrl, "_blank");
  };

  const getExpiryDate = () => {
    switch (ancillaryType) {
      case "securingRopes":
        return addMonths(
          manufactureDate,
          company.maxSecuringRopesLife
        ).toLocaleDateString("en-GB");
      case "manropes":
        return addMonths(
          manufactureDate,
          company.maxManropesLife
        ).toLocaleDateString("en-GB");
      case "accommodationLadderFalls":
        return addMonths(
          manufactureDate,
          company.maxAccomLdrFallsLife
        ).toLocaleDateString("en-GB");
      case "embarkationLadders":
        return addMonths(
          manufactureDate,
          company.maxEmbarkationLife
        ).toLocaleDateString("en-GB");
      default:
        return manufactureDate;
    }
  };

  const addMonths = (date, months) => {
    var result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  };

  // Mutations
  const ancillaryAddMutation = useMutation(
    () => {
      const formData = new FormData();
      formData.append("serialNumber", serialNumber);
      formData.append("manufactureDate", manufactureDate);
      formData.append("type", ancillaryType);
      formData.append(`files.file`, certificateFile, certificateFile.name);

      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/vessels/${vesselId}/addEquipment`,
        formData,
        {
          headers: {
            Accept: "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["user"]);
        queryClient.invalidateQueries(["vessel", vesselId]);
        // Clear form fields
        setSerialNumber("");
        setManufactureDate("");
        setCertificateFile(null);
      },
    }
  );
  const ancillaryUpdateMutation = useMutation(
    () => {
      const formData = new FormData();
      formData.append("serialNumber", serialNumber);
      formData.append("manufactureDate", manufactureDate);
      formData.append("type", ancillaryType);
      formData.append(`files.file`, certificateFile, certificateFile.name);

      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/vessels/${vesselId}/${itemId}/updateEquipment`,
        formData,
        {
          headers: {
            Accept: "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["user"]);
        queryClient.invalidateQueries(["vessel", vesselId]);
        // Clear form fields
        setSerialNumber("");
        setManufactureDate("");
        setCertificateFile(null);
      },
    }
  );

  const saveAncillary = () => {
    let errors = [];

    if (
      serialNumber === "" ||
      serialNumber === null ||
      serialNumber === undefined
    ) {
      errors.push("Serial/Certificate Number is required");
    }

    if (
      manufactureDate === "" ||
      manufactureDate === null ||
      manufactureDate === undefined
    ) {
      errors.push("Manufacture Date is required");
    }

    if (
      certificateFile === "" ||
      certificateFile === null ||
      certificateFile === undefined
    ) {
      errors.push("Certificate is required");
    }

    if (errors.length > 0) {
      setErrorMessage(errors.join(", "));
      setErrorDialogOpen(true);
    } else {
      itemId ? ancillaryUpdateMutation.mutate() : ancillaryAddMutation.mutate();
      setShowModal(false);
    }
  };

  const showDeleteAncillary = (itemId) => {
    setShowDeleteAncillaryModal(true);
  };

  const closeAllModals = () => {
    setShowModal(false);
    setShowDeleteAncillaryModal(false);
  };

  return (
    <>
      <div>
        <Transition.Root show={showModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            initialFocus={cancelButtonRef}
            onClose={setShowModal}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-fathom-blue">
                      <FontAwesomeIcon
                        icon={faAnchor}
                        className="h-6 w-6 text-white"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        {type ? formatTypeName(type) : "Empty"}{" "}
                        {ancillaryType === "accommodationLadderFalls"
                          ? ancillaryItemIndex === 0
                            ? "Port"
                            : ancillaryItemIndex === 1
                            ? "Starboard"
                            : ancillaryItemIndex + 1
                          : ancillaryItemIndex + 1}
                      </Dialog.Title>

                      <div className="text-sm">
                        {ancillaryItemEdit?.serialNumber && (
                          <p className="text-gray-500">
                            {ancillaryItemEdit?.serialNumber}
                          </p>
                        )}
                        {ancillaryItemEdit?.manufactureDate && (
                          <p>
                            <span className="text-gray-500">Expiry:</span>{" "}
                            {getExpiryDate()}
                          </p>
                        )}
                      </div>

                      {ancillaryItemEdit?.certificate && (
                        <button
                          type="button"
                          className="inline-flex items-center mt-3 px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
                          onClick={getAncillaryCertificate}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-3 h-3 mr-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                            />
                          </svg>
                          Download Certificate
                        </button>
                      )}
                    </div>
                  </div>

                  {type && (
                    <div className="mt-5">
                      <h2>Replace {formatTypeName(type)}</h2>
                    </div>
                  )}

                  <div className="mt-5">
                    <label
                      htmlFor="serialNumber"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Serial Number
                    </label>
                    <input
                      type="text"
                      name="serialNumber"
                      id="serialNumber"
                      onChange={(e) => setSerialNumber(e.target.value)}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                    />
                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="manufactureDate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Date of Manufacture
                    </label>
                    <input
                      type="date"
                      name="manufactureDate"
                      id="manufactureDate"
                      onChange={(e) => setManufactureDate(e.target.value)}
                      max={
                        new Date(new Date().setDate(new Date().getDate() - 1))
                          .toISOString()
                          .split("T")[0]
                      }
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                    />
                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="certificate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {itemId ? "Replace Certificate" : "Upload Certificate"}
                    </label>
                    <input
                      type="file"
                      name="certificate"
                      id="certificate"
                      accept=".pdf" // specify the file type if necessary
                      onChange={(e) => setCertificateFile(e.target.files[0])}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                    />
                  </div>

                  <div
                    className={`mt-5 flex ${
                      itemId ? "justify-between" : "justify-end"
                    }`}
                  >
                    {itemId && (
                      <button
                        type="button"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-red-600 bg-red-50 hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300"
                        onClick={() => showDeleteAncillary(itemId)}
                      >
                        Delete
                      </button>
                    )}
                    <div>
                      <button
                        type="button"
                        className="mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
                        onClick={() => setShowModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-fathom-blue hover:bg-fathom-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fathom-blue-dark"
                        onClick={saveAncillary}
                      >
                        {itemId ? "Update" : "Add"}
                      </button>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
            <ErrorDialog
              open={errorDialogOpen}
              onClose={() => setErrorDialogOpen(false)}
              title="Error"
              onConfirm={() => setErrorDialogOpen(false)}
            >
              {errorMessage}
            </ErrorDialog>
          </Dialog>
        </Transition.Root>
      </div>
      <DeleteAncillaryModal
        showModal={showDeleteAncillaryModal}
        setShowModal={setShowDeleteAncillaryModal}
        itemId={itemId}
        vesselId={vesselId}
        closeAllModals={closeAllModals}
      />
    </>
  );
}
