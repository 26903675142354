import React, { useState, useEffect } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";
import getBoardingTypes from "../../data/queries/getBoardingTypes";
import getJobRoles from "../../data/queries/getJobRoles";

export default function RenewModal({
  showModal,
  setShowModal,
  userLoggedIn,
  onSuccess,
}) {
  const [boardingArrangement, setBoardingArrangement] = useState(0);
  const [role, setRole] = useState(0);

  const { data: boardingTypesData, status: boardingTypesStatus } = useQuery(
    ["boardingTypes"],
    () => getBoardingTypes()
  );
  const { data: jobRoleData, status: jobRoleStatus } = useQuery(
    ["jobRoles"],
    () => getJobRoles()
  );

  useEffect(() => {
    setBoardingArrangement(
      userLoggedIn.user_boarding_type && userLoggedIn.user_boarding_type.id
    );
    setRole(userLoggedIn.user_role && userLoggedIn.user_role.id);
  }, [userLoggedIn]);

  const queryClient = useQueryClient();

  // Mutations
  const doRenewMutation = useMutation(
    () => {
      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/user-modules/renew`,
        {
          boardingArrangementId: boardingArrangement,
          userRoleId: role,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["user", "module"]);
        onSuccess();
      },
    }
  );

  const clickConfirm = () => {
    doRenewMutation.mutate();
    setShowModal(false);
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Renew Certification
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="mb-4 text-slate-500 text-sm leading-relaxed">
                    Please confirm your boarding arrangement and role before
                    renewing your certification. Once you confirm your renewal,
                    new course modules will be loaded to complete.
                  </p>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="role"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Role
                      </label>
                      <select
                        id="role"
                        name="role"
                        autoComplete="off"
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-fathom-blue focus:outline-none focus:ring-fathom-blue sm:text-sm"
                        onChange={(e) => setRole(e.target.value)}
                        value={role}
                      >
                        {jobRoleStatus == "success" &&
                          jobRoleData.data.map((jobRole) => (
                            <option key={jobRole.id} value={jobRole.id}>
                              {jobRole.RoleName}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="boardingArrangement"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Boarding Arrangement
                      </label>
                      <select
                        id="boardingArrangement"
                        name="boardingArrangement"
                        autoComplete="off"
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-fathom-blue focus:outline-none focus:ring-fathom-blue sm:text-sm"
                        onChange={(e) => setBoardingArrangement(e.target.value)}
                        value={boardingArrangement}
                      >
                        {boardingTypesStatus == "success" &&
                          boardingTypesData.data.map((boardingArrangement) => (
                            <option
                              key={boardingArrangement.id}
                              value={boardingArrangement.id}
                            >
                              {boardingArrangement.BoardingTypeName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 uppercase background-transparent font-bold  px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="text-white bg-fathom-blue hover:bg-fathom-dark-blue font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center uppercase"
                    type="button"
                    onClick={clickConfirm}
                  >
                    Renew
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
