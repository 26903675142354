import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Vimeo from '@vimeo/player';
import SimpleQuestion from "../../components/SimpleQuestion/SimpleQuestion";
import ModuleStart from "../../components/ModuleStart/ModuleStart";
import ModulePagination from "../../components/ModulePagination/ModulePagination";
import StartModal from "./StartModal";
import FinishModal from "./FinishModal";
import ModuleResult from "./ModuleResult";

// Mutations
import useFinishModule from "../../data/hooks/useFinishModule";
import useResetModule from "../../data/hooks/useResetModule";
import useUpdateModuleState from "../../data/hooks/useUpdateModuleState";

// Queries
import getModule from "../../data/queries/getModule";

export default function Module({ modules }) {
  let { id } = useParams();

  // Default Number of Questions
  const numberOfQuestions = 5;

  // Actions
  const { mutate: finishModuleMutation } = useFinishModule();
  const { mutate: resetModuleMutation } = useResetModule();
  const { mutate: updateModuleStateMutation } = useUpdateModuleState();

  // State
  const [moduleStage, setModuleStage] = React.useState(0);
  const [stateModuleData, setStateModuleData] = React.useState({});
  const [selectedQuestionIds, setSelectedQuestionIds] = React.useState([]);
  const [stateModuleUserData, setStateModuleUserData] = React.useState({});
  const [moduleStatus, setModuleStatus] = React.useState("");
  const [startModalOpen, setStartModalOpen] = useState(false);
  const [finishModalOpen, setFinishModalOpen] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [progress, setProgress] = useState(0);
  const vimeoContainer = useRef(null);

  // Queries
  const { data: moduleData, status } = useQuery(["module", id], () =>
    getModule(id)
  );

  // Move the module data to state once its loaded as other data needs to know when its changed
  useEffect(() => {
    if (moduleData && status == "success") {
      setStateModuleData(moduleData.data.data.attributes);
    }
  }, [moduleData]);

  const moduleStateId =
    modules && modules.find((module) => module.module.id == id).id;

  const selectModuleStage = (stage) => {
    setModuleStage(stage);
    let currentState = JSON.parse(JSON.stringify(stateModuleUserData)) ?? {};
    currentState["currentStage"] = stage;
    setStateModuleUserData(currentState);
    updateModuleStateMutation({ currentState, moduleStateId });
  };

  const answerQuestion = (questionId, answerId) => {
    let currentState = JSON.parse(JSON.stringify(stateModuleUserData)) ?? {};
    
    if (currentState.answered) {
      currentState.answered[questionId] = answerId;
    } else {
      currentState.answered = { [questionId]: answerId };
    }

    setStateModuleUserData(currentState);
    updateModuleStateMutation({ currentState, moduleStateId });
  };

  // When user modules data is loaded or changed, update the local state
  useEffect(() => {
    if (modules) {
      let retrievedStatus = modules.find(
        (module) => module.module.id == id
      ).status;
      let retrievedState = modules.find(
        (module) => module.module.id == id
      ).state;
      let retrievedQuestionIds = modules.find(
        (module) => module.module.id == id
      ).selectedQuestionIds;

      if (retrievedState != null) {
        setStateModuleUserData(retrievedState);
        setModuleStage(retrievedState.currentStage);
        setModuleStatus(retrievedStatus);
        setSelectedQuestionIds(retrievedQuestionIds);
      }
    }
  }, [modules]);

  // Fire when the users module state has been set or updated
  //
  React.useEffect(() => {
    // Only process once the static module data has been lodaed
    let selected = [];
    if (
      status == "success" &&
      moduleData.data &&
      moduleData.data.data &&
      stateModuleUserData
    ) {
      let theseSelectedQuestions = modules.find(
        (module) => module.module.id == id
      ).selectedQuestionIds;
      // If the questions for this module have not been randomly selected, do so now
      if (!theseSelectedQuestions || theseSelectedQuestions.length == 0) {

        let questionIdArray =
          moduleData.data.data.attributes.questions.data.map(
            (question) => question.id
          );
        // Shuffle questions and pick
        const shuffledQuestions = questionIdArray.sort(
          () => 0.5 - Math.random()
        );
        selected = shuffledQuestions.slice(0, numberOfQuestions);

        let currentState =
          JSON.parse(JSON.stringify(stateModuleUserData)) ?? {};
        setSelectedQuestionIds(selected);
        updateModuleStateMutation({
          moduleStateId,
          selectedQuestionIds: selected,
        });
      }
    }
  }, [stateModuleUserData]);

  useEffect(() => {
    let selectedQuestionsV = [];
    if (stateModuleData.questions) {
      selectedQuestionsV = stateModuleData.questions.data.filter(function (
        item
      ) {
        return selectedQuestionIds.indexOf(item.id) > -1;
      });
    }
    setSelectedQuestions(selectedQuestionsV);
  }, [selectedQuestionIds, stateModuleData]);

  // User has clicked start after viewing video
  const moduleStart = () => {
    setSelectedModule(moduleData.data);
    setStartModalOpen(true);
  };

  // User has clicked start confirmation modal (after viewing video)
  const goToModule = () => {
    selectModuleStage(1);
  };

  // Get the id that the user answered for a question
  const getAnsweredId = (questionId) => {
    if (stateModuleUserData.answered) {
      if (stateModuleUserData.answered.hasOwnProperty(questionId)) {
        return stateModuleUserData.answered[questionId];
      } else return null;
    }
  };

  // User has clicked Finish
  const onFinish = () => {
    try {
      let answeredCount = Object.keys(stateModuleUserData.answered).length;
      let questionCount = selectedQuestions.length;
      if (answeredCount < questionCount) {
        setFinishModalOpen(true);
      } else {
        finishModuleMutation({ moduleStateId });
      }
    } catch (e) {
      setFinishModalOpen(true);
    }
  };

  // User has clicked finish within the finish modal
  const onFinishFinal = () => {
    finishModuleMutation({ moduleStateId });
  };

  // User has clicked restart from the final score screen
  const restartModule = () => {
    resetModuleMutation({ moduleStateId });
  };

  useEffect(() => {
    if (vimeoContainer.current) {
      const player = new Vimeo(vimeoContainer.current, {
        url: stateModuleData.VimeoUrl,
        responsive: true,
      });
      
      player.on('timeupdate', (data) => {
        const progressPercent = (data.seconds / data.duration) * 100;
        setProgress(progressPercent);
      });
      // Cleanup on unmount
      return () => {
        player.off('timeupdate');
      };
    }
  }, [stateModuleData, vimeoContainer.current]);

  return (
    <>
      {status == "success" &&
        stateModuleData.questions &&
        (moduleStatus == "NEW" || moduleStatus == "INPROGRESS") && (
          <div>
            <div className="sm:flex sm:justify-between sm:items-baseline pb-5 border-b border-gray-200">
              <div className="sm:w-0 sm:flex-1">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  {stateModuleData.ModuleName}
                </h2>
                <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                      />
                    </svg>
                    {numberOfQuestions} Questions
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    Approximately {stateModuleData.moduleTimeMinutes} minutes
                  </div>
                </div>
              </div>

              <div className="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:justify-start">
                {moduleStage > 0 && (
                  <button
                    type="button"
                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-fathom-blue hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                    onClick={onFinish}
                  >
                    Finish Test
                  </button>
                )}
              </div>
            </div>

            {moduleStage == 0 && (
              <div className="sm:flex sm:justify-between sm:items-baseline pt-5 pb-5">
                <div className="sm:w-0 sm:flex-1">
                  <p className="text-sm">{stateModuleData.moduleDescription}</p>
                </div>
              </div>
            )}

            {/* Show start page if module is just starting */}

            {moduleStage == 0 && (
              <>
                <div ref={vimeoContainer} className="py-3" />
                <ModuleStart
                  moduleName={stateModuleData.ModuleName}
                  moduleDescription={stateModuleData.moduleDescription}
                  moduleStart={moduleStart}
                  videoProgress = {progress}
                />
              </>
            )}

            {/* Render questions depending on the stage */}

            {moduleStage > 0 && selectedQuestions.length > 0 && (
              <SimpleQuestion
                question={selectedQuestions[moduleStage - 1]}
                questionNo={moduleStage}
                answerQuestion={answerQuestion}
                answered={getAnsweredId(selectedQuestions[moduleStage - 1].id)}
              />
            )}

            {moduleStage > 0 && (
              <ModulePagination
                {...{
                  moduleStage,
                  selectedQuestions,
                  selectModuleStage,
                  onFinish,
                }}
              />
            )}
          </div>
        )}

      {status == "success" &&
        stateModuleData.questions &&
        (moduleStatus == "COMPLETE" || moduleStatus == "FAILED") && (
          <ModuleResult
            stateModuleUserData={stateModuleUserData}
            restartModule={restartModule}
          />
        )}
      <StartModal
        open={startModalOpen}
        setOpen={setStartModalOpen}
        startClicked={goToModule}
        selectedModule={selectedModule}
      />
      <FinishModal
        open={finishModalOpen}
        setOpen={setFinishModalOpen}
        finishClicked={onFinishFinal}
        selectedModule={selectedModule}
      />
    </>
  );
}
