import axios from 'axios';


export default function getInviteByCode(id) {
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/invites/detail`,
        {inviteId: id},
        {
          headers: {
            Accept: "application/json"
          },
        }
      );
  }