import React from "react";

export default function ModuleStart({
  moduleName,
  moduleDescription,
  moduleStart,
  videoProgress
}) {
  return (
    <button
      type="button"
      className={`inline-flex items-center px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 ${videoProgress < 95 ? 'bg-gray-300 cursor-default' : 'bg-fathom-blue hover:bg-fathom-dark-blue'}`}
      title={videoProgress<95?"Please watch at least 95% of the video to start the test.":""}
      onClick={moduleStart}
      disabled={videoProgress<95}
    >
      Start Test
    </button>
  );
}
