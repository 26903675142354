import React, { useEffect, useRef } from "react";

const LadderProgressCircle = ({
  outerPercentage,
  innerPercentage,
  text,
  outerColor,
  innerColor,
}) => {
  if (outerPercentage > 92) {
    outerColor = "#d50c54";
  } else if (outerPercentage > 80) {
    outerColor = "#FFBF00";
  } else {
    outerColor = "#025839";
  }

  const radius = 40;
  const strokeWidth = 8;
  const borderStrokeWidth = 0.5;
  const viewBoxSize = radius * 2 + strokeWidth * 2;

  const circumference = 2 * Math.PI * radius;

  const outerDashOffset = (circumference * (100 - outerPercentage)) / 100;
  const innerCircumference = 2 * Math.PI * (radius - strokeWidth);
  const innerDashOffset = (innerCircumference * (100 - innerPercentage)) / 100;

  const containerRef = useRef(null);

  return (
    <div ref={containerRef} className="flex items-center justify-center">
      <svg
        className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl"
        viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Outer Ring Border */}
        <circle
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          r={radius}
          stroke="#D1D5DB"
          strokeWidth={borderStrokeWidth}
          fill="transparent"
          transform="rotate(-90, 50, 50)"
        />

        {/* Outer Ring */}
        <circle
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          r={radius}
          stroke={outerColor || "#4CAF50"}
          strokeWidth={strokeWidth}
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={outerDashOffset}
          transform="rotate(-90, 50, 50)"
        />

        {/* Inner Ring Border */}
        <circle
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          r={radius - strokeWidth}
          stroke="#D1D5DB"
          strokeWidth={borderStrokeWidth}
          fill="transparent"
          transform="rotate(-90, 50, 50)"
        />

        {/* Inner Ring */}
        <circle
          className="inner-ring"
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          r={radius - strokeWidth}
          stroke={innerColor || "#D1D5DB"}
          strokeWidth={strokeWidth}
          fill="transparent"
          strokeDasharray={innerCircumference}
          strokeDashoffset={innerDashOffset}
          transform="rotate(-90, 50, 50)"
        />

        {/* Text in the middle */}
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          alignmentBaseline="middle"
          fill="#000"
          fontSize="8px"
        >
          {text}
        </text>
      </svg>
    </div>
  );
};

export default LadderProgressCircle;
