import React from "react";

const getFile = ({ file }) => {
  const getFileUrl = () => {
    if (file.url.startsWith("https://")) {
      // File is hosted on S3
      return file.url;
    } else {
      // File is hosted locally
      return process.env.REACT_APP_MEDIA_ROOT + file.url;
    }
  };

  const fileUrl = getFileUrl();

  return fileUrl;
};

export default getFile;
