import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../services/utils";
import StartModal from "../Module/StartModal";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import CalendarIcon from "@heroicons/react/24/outline/CalendarIcon";
import PageHeader from "../../components/PageHeader/PageHeader";

export default function ModuleHistory() {
  const navigate = useNavigate();

  const {
    status,
    data: history,
    error,
    isFetching,
  } = useQuery(["history"], () => {
    let token =
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).jwt;
    if (token) {
      return axios.get(
        `${process.env.REACT_APP_API_ROOT}/users/me?populate[user_module_histories][populate][0]=module`,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    } else return null;
  });

  const retakeModule = (module) => {
    navigate("/module/" + module.module.id);
  };

  return (
    <>
      <div className="relative pb-5 border-b border-gray-200 sm:pb-0">
        <PageHeader title="My History" />
        <div className="mt-4">
          <div className="my-5">
            <ul role="list" className="space-y-3">
              {status == "success" &&
                history &&
                history.data &&
                history.data.user_module_histories
                  .sort(
                    (sv, sb) => new Date(sb.createdAt) - new Date(sv.createdAt)
                  )
                  .map((thisHistory) => (
                    <li
                      className="bg-white shadow overflow-hidden px-4 py-4 sm:px-6 sm:rounded-md"
                      key={thisHistory.id}
                    >
                      <div className="flex items-center justify-between">
                        <p className="truncate text-gray-800">
                          {thisHistory.module.ModuleName}
                        </p>
                        <span
                          className={classNames(
                            thisHistory.result == "FAILED"
                              ? "bg-red-100 text-red-800"
                              : "bg-green-100 text-green-800",
                            "inline-flex items-center px-2 py-0.5 rounded text-xs font-medium"
                          )}
                        >
                          {" "}
                          {thisHistory.result}{" "}
                        </span>
                      </div>
                      <div className="mt-2 flex items-center justify-between">
                        <div className="flex justify-between">
                          <CalendarIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-fathom-blue"
                            aria-hidden="true"
                          />
                          <p className="text-sm text-gray-500">
                            {new Date(thisHistory.createdAt).toLocaleDateString(
                              "en-GB"
                            )}{" "}
                            {new Date(thisHistory.createdAt).toLocaleTimeString(
                              "en-GB"
                            )}
                          </p>
                        </div>
                        <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                          <p className="flex items-center text-sm text-gray-500">
                            <span className={"ml-2"}>
                              {thisHistory.questionsCorrect} /{" "}
                              {thisHistory.questionsTotal}
                            </span>
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
              {status == "success" &&
                history &&
                history.data &&
                history.data.user_module_histories.length == 0 && (
                  <p className="text-center text-sm text-gray-400 p-5">
                    - You have no module histoy -
                  </p>
                )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
