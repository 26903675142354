import React, { useState, useContext } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import axios from "axios";
import { CompanyContext } from "../../context/CompanyContext";
import getImage from "../../components/GetImage/GetImage";
import AddUpdateVesselModal from "./AddUpdateVesselModal";
import DeleteVesselModal from "./DeleteVesselModal";
import PencilSquareIcon from "@heroicons/react/24/outline/PencilSquareIcon";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";

const MyFleetGrid = ({ data }) => {
  const queryClient = useQueryClient();
  const company = useContext(CompanyContext);
  const [showEditVesselModal, setShowEditVesselModal] = useState(false);
  const [showDeleteVesselModal, setShowDeleteVesselModal] = useState(false);
  const [selectedVesselId, setSelectedVesselId] = useState(null);
  const [isUpdate, setIsEdit] = useState(false);
  const [editVessel, setEditVessel] = useState({});

  const showEditVessel = (vesselId, vessel) => {
    setSelectedVesselId(vesselId);
    setIsEdit(true);
    setEditVessel(vessel);
    setShowEditVesselModal(true);
  };

  const showDeleteVessel = (vesselId) => {
    setSelectedVesselId(vesselId);
    setShowDeleteVesselModal(true);
  };

  const deleteVessel = (id) => {
    deleteMutation.mutate(id);
    setSelectedVesselId(null);
    setShowDeleteVesselModal(false);
  };

  // Mutations
  const deleteMutation = useMutation(
    (id) => {
      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/vessels/delete`,
        {
          vesselId: id,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["users"]);
      },
    }
  );

  // Grid Circle Colors
  const currentDate = new Date();
  const addMonths = (date, months) => {
    var result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  };
  const calculateCircleColor = (manufacturerDate, maxLife, type) => {
    const expiryDate = addMonths(new Date(manufacturerDate), maxLife);
    const timeDiff = expiryDate.getTime() - currentDate.getTime();
    const diffMonths = Math.floor(timeDiff / (1000 * 3600 * 24 * 30));
    let circleColor = "white";
    if (manufacturerDate) {
      if (diffMonths < 0) {
        circleColor = "red";
      } else if (
        type === "securingRopes" ||
        type === "manropes" ||
        type === "pilotLadders"
      ) {
        if (diffMonths <= 2) {
          circleColor = "yellow";
        } else {
          circleColor = "green";
        }
      } else if (type === "accommodationLadderFalls") {
        if (diffMonths <= 6) {
          circleColor = "yellow";
        } else {
          circleColor = "green";
        }
      } else if (type === "embarkationLadders") {
        console.log(diffMonths)
        console.log(maxLife)
        console.log(type)
        console.log(manufacturerDate)
        if (diffMonths <= 4) {
          circleColor = "yellow";
        } else {
          circleColor = "green";
        }
      }
    }
    return circleColor;
  };

  return (
    <>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3"
      >
        {data.map((vessel) => (
          <li
            key={vessel.id}
            className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
          >
            <div className="flex-1 flex flex-col p-6">
              <div className="flex justify-end space-x-2">
                <div
                  className="text-fathom-blue border border-fathom-blue hover:bg-fathom-blue hover:text-white hover:cursor-pointer focus:ring-4 focus:outline-none focus:ring-fathom-dark-blue font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center"
                  onClick={() => showEditVessel(vessel.id, vessel)}
                >
                  <PencilSquareIcon className="h-3 w-3" />
                </div>

                {/* <TrashIcon
                  className="h-3 w-3 hover:cursor-pointer"
                  onClick={() => showDeleteVessel(vessel.id)}
                /> */}
              </div>

              <h3 className="mb-3 text-gray-900 text-lg font-medium">
                <Link to={`/vessel/${vessel.id}`}>{vessel.name}</Link>
              </h3>
              <Link to={`/vessel/${vessel.id}`}>
                <img
                  className="w-32 h-32 flex-shrink-0 mx-auto rounded-full"
                  src={getImage({
                    image: vessel.photo,
                    desiredFormat: "small",
                  })}
                  alt={vessel.name}
                />
              </Link>

              <dl className="mt-3 flex-grow flex flex-col justify-between">
                <dt className="sr-only">Type</dt>
                <dd className="">
                  <span className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                    {vessel.type}
                  </span>
                </dd>
                <dt className="sr-only">Email</dt>
                <dd className="mt-3 text-gray-500 text-sm">
                  <a
                    href={`mailto:${vessel.email}`}
                    className="relative flex-1 inline-flex items-center justify-center text-sm text-gray-700 font-medium hover:text-gray-500"
                  >
                    {vessel.email}
                  </a>
                </dd>
              </dl>

              <div className="border-t border-gray-200 mt-3 py-5">
                {/*
                <dl className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Length
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {vessel.length}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Tails</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {vessel.tails}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Steps</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {vessel.steps}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Spreaders
                    </dt>
                    {vessel.spreaders}
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Rubber Steps
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {vessel.rubberSteps}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Securing
                    </dt>
                    {vessel.securing}
                  </div>
                </dl>
                */}
                <div className="flex flex-wrap">
                  <div className="w-full mb-4">
                    <div>
                      <h3>Pilot Ladders</h3>
                      {vessel.ladder1 || vessel.ladder2 || vessel.ladder3 ? (
                        <div className="flex flex-wrap justify-center">
                          {vessel.ladder1 && vessel.ladder1.id && (
                            <div
                              key={
                                vessel.ladder1.id ||
                                `placeholder-${vessel.ladder1.id}`
                              }
                              className="flex items-center justify-center cursor-pointer mb-1 mr-1"
                            >
                              <div
                                className={`w-8 h-8 bg-${calculateCircleColor(
                                  vessel.ladder1.manufactureDate,
                                  company.maxLadderLife,
                                  "pilotLadders"
                                )}-500 rounded-full border border-gray-300 flex items-center justify-center`}
                              ></div>
                            </div>
                          )}
                          {vessel.ladder2 && vessel.ladder2.id && (
                            <div
                              key={
                                vessel.ladder2.id ||
                                `placeholder-${vessel.ladder2.id}`
                              }
                              className="flex items-center justify-center cursor-pointer mb-1 mr-1"
                            >
                              <div
                                className={`w-8 h-8 bg-${calculateCircleColor(
                                  vessel.ladder2.manufactureDate,
                                  company.maxLadderLife,
                                  "pilotLadders"
                                )}-500 rounded-full border border-gray-300 flex items-center justify-center`}
                              ></div>
                            </div>
                          )}
                          {vessel.ladder3 && vessel.ladder3.id && (
                            <div
                              key={
                                vessel.ladder3.id ||
                                `placeholder-${vessel.ladder3.id}`
                              }
                              className="flex items-center justify-center cursor-pointer mb-1 mr-1"
                            >
                              <div
                                className={`w-8 h-8 bg-${calculateCircleColor(
                                  vessel.ladder3.manufactureDate,
                                  company.maxLadderLife,
                                  "pilotLadders"
                                )}-500 rounded-full border border-gray-300 flex items-center justify-center`}
                              ></div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <p className="text-xs text-gray-500">- No Data -</p>
                      )}
                    </div>
                  </div>
                  <div className="w-1/2 pr-4 mb-4">
                    <div>
                      <h3>Securing Ropes</h3>
                      {vessel.ancillary_equipments.filter(
                        (equipment) => equipment.type === "securingRopes"
                      ).length === 0 ? (
                        <p className="text-xs text-gray-500">- No Data -</p>
                      ) : (
                        <div className="flex flex-wrap justify-center">
                          {vessel.ancillary_equipments
                            .filter(
                              (equipment) => equipment.type === "securingRopes"
                            )
                            .map((equipment, index) => (
                              <div
                                key={equipment.id || `placeholder-${index}`}
                                className="flex items-center justify-center cursor-pointer mb-1 mr-1"
                              >
                                <div
                                  className={`w-8 h-8 bg-${calculateCircleColor(
                                    equipment.manufactureDate,
                                    company.maxSecuringRopesLife,
                                    equipment.type
                                  )}-500 rounded-full border border-gray-300 flex items-center justify-center`}
                                ></div>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="w-1/2 mb-4">
                    <div>
                      <h3>Embarkation Ladders</h3>
                      {vessel.ancillary_equipments.filter(
                        (equipment) => equipment.type === "embarkationLadders"
                      ).length === 0 ? (
                        <p className="text-xs text-gray-500">- No Data -</p>
                      ) : (
                        <div className="flex flex-wrap justify-center">
                          {vessel.ancillary_equipments
                            .filter(
                              (equipment) =>
                                equipment.type === "embarkationLadders"
                            )
                            .map((equipment, index) => (
                              <div
                                key={equipment.id || `placeholder-${index}`}
                                className="flex items-center justify-center cursor-pointer mb-1 mr-1"
                              >
                                <div
                                  className={`w-8 h-8 bg-${calculateCircleColor(
                                    equipment.manufactureDate,
                                    company.maxEmbarkationLife,
                                    equipment.type
                                  )}-500 rounded-full border border-gray-300 flex items-center justify-center`}
                                ></div>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="w-1/2 pr-4 mb-4">
                    <div>
                      <h3>Manropes</h3>
                      {vessel.ancillary_equipments.filter(
                        (equipment) => equipment.type === "manropes"
                      ).length === 0 ? (
                        <p className="text-xs text-gray-500">- No Data -</p>
                      ) : (
                        <div className="flex flex-wrap justify-center">
                          {vessel.ancillary_equipments
                            .filter(
                              (equipment) => equipment.type === "manropes"
                            )
                            .map((equipment, index) => (
                              <div
                                key={equipment.id || `placeholder-${index}`}
                                className="flex items-center justify-center cursor-pointer mb-1 mr-1"
                              >
                                <div
                                  className={`w-8 h-8 bg-${calculateCircleColor(
                                    equipment.manufactureDate,
                                    company.maxManropesLife,
                                    equipment.type
                                  )}-500 rounded-full border border-gray-300 flex items-center justify-center`}
                                ></div>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="w-1/2 mb-4">
                    <div>
                      <h3>Accomodation Falls</h3>
                      {vessel.ancillary_equipments.filter(
                        (equipment) =>
                          equipment.type === "accommodationLadderFalls"
                      ).length === 0 ? (
                        <p className="text-xs text-gray-500">- No Data -</p>
                      ) : (
                        <div className="flex flex-wrap justify-center">
                          {vessel.ancillary_equipments
                            .filter(
                              (equipment) =>
                                equipment.type === "accommodationLadderFalls"
                            )
                            .map((equipment, index) => (
                              <div
                                key={equipment.id || `placeholder-${index}`}
                                className="flex items-center justify-center cursor-pointer mb-1 mr-1"
                              >
                                <div
                                  className={`w-8 h-8 bg-${calculateCircleColor(
                                    equipment.manufactureDate,
                                    company.maxAccomLdrFallsLife,
                                    equipment.type
                                  )}-500 rounded-full border border-gray-300 flex items-center justify-center`}
                                ></div>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <AddUpdateVesselModal
        showModal={showEditVesselModal}
        setShowModal={setShowEditVesselModal}
        isUpdate={isUpdate}
        vesselId={selectedVesselId}
        editVessel={editVessel}
      />
      <DeleteVesselModal
        showModal={showDeleteVesselModal}
        setShowModal={setShowDeleteVesselModal}
        deleteVessel={deleteVessel}
        vesselId={selectedVesselId}
      />
    </>
  );
};

export default MyFleetGrid;
