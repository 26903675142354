import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

/**
 * Completes module by calling custom back end function
 * @returns {any}
 */
export default function useFinishModule() {
  const queryClient = useQueryClient();

  return useMutation(
    ({moduleStateId}) => {
      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/user-modules/finish`,
        {
          id: moduleStateId,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["user"]);
      },
    }
  );
}
