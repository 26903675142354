import axios from 'axios';


export default function getModule(id) {
    let token =
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).jwt;
    if (token) {
      // Hydrate the full hierarchy
      //populate[questions][populate][0]=answers`,
      return axios.get(
        `${process.env.REACT_APP_API_ROOT}/question-sections/${id}?populate=deep`,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    }
  }