import axios from 'axios';


export default function getMyCerts() {
    let token =
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).jwt;
    if (token) {
      return axios.get(
        `${process.env.REACT_APP_API_ROOT}/user-modules/mycerts`,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    }
  }