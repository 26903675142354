import React, { Fragment, useEffect, useState } from "react";
import { classNames } from "../../services/utils";
import CalendarIcon from "@heroicons/react/24/outline/CalendarIcon";
import PageHeader from "../../components/PageHeader/PageHeader";
import getMyCerts from "../../data/queries/getMyCerts";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import RenewModal from "./RenewModal";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

export default function Certifications({
  userLoggedIn,
  displaySomeToast,
  setSelectedIndex,
}) {
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const today = new Date().toLocaleDateString("en-GB");

  // Queries
  const { data: certs, status } = useQuery(["certs"], () => getMyCerts());

  const renewCert = () => {
    setShowModal(true);
  };

  const renewSuccess = () => {
    displaySomeToast(
      "Renewal Started! New modules are available for you to complete!"
    );
    setSelectedIndex(1);
    navigate("/course");
  };

  const getPdf = (id) => {
    let token =
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).jwt;
    return axios
      .post(
        `${process.env.REACT_APP_API_ROOT}/user-modules/getcert`,
        { id: id },
        {
          headers: {
            Accept: "application/pdf",
            Authorization: "Bearer " + token,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });

        const fileURL = URL.createObjectURL(file);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "certificate.pdf";
        alink.click();
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  return (
    <div className="relative pb-5 border-b border-gray-200 sm:pb-0">
      <PageHeader title="My Certifications" />
      <div className="mt-4">
        <div className="sm:hidden">
          <select
            id="current-tab"
            name="current-tab"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option onSelect={() => setSelectedTab(0)}>Active</option>
            <option onSelect={() => setSelectedTab(1)}>Expired</option>
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            <a
              href="#"
              onClick={() => setSelectedTab(0)}
              className={classNames(
                selectedTab == 0
                  ? "border-fathom-blue text-fathom-blue"
                  : "border-transparent",
                "text-gray-500 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current="page"
            >
              Active Certifications
            </a>
            <a
              href="#"
              onClick={() => setSelectedTab(1)}
              className={classNames(
                selectedTab == 1
                  ? "border-fathom-blue text-fathom-blue"
                  : "border-transparent",
                " text-gray-500 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
              )}
            >
              Expired Certifications
            </a>
          </nav>
        </div>
        <div className="my-5">
          <ul role="list" className="space-y-3">
            {certs &&
              status == "success" &&
              certs.data.length > 0 &&
              certs.data.map(
                (cert) =>
                  ((selectedTab === 0 && cert.hasExpired === false) ||
                    (selectedTab === 1 && cert.hasExpired === true)) && (
                    <li
                      className="bg-white shadow overflow-hidden px-4 py-4 sm:px-6 sm:rounded-md"
                      key={cert.id}
                    >
                      <div className="flex justify-between text-gray-800">
                        <dt>{cert.courseName} Course Certification</dt>
                        <dd className="flex justify-between">
                          {!cert.isRenewable && (
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                              Valid until{" "}
                              {new Date(cert.expiry).toLocaleDateString(
                                "en-GB"
                              )}
                            </span>
                          )}
                          {cert.isRenewable && (
                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800 ml-3">
                              {cert.hasExpired ? "Expired" : "Expires"} on{" "}
                              {new Date(cert.expiry).toLocaleDateString(
                                "en-GB"
                              )}
                            </span>
                          )}
                          {cert.isRenewable && (
                            <button
                              type="button"
                              className="inline-flex items-center px-2.5 py-1.5 ml-3 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
                              onClick={() => renewCert()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-3 h-3 mr-1 text-fathom-blue"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                />
                              </svg>
                              Renew Now
                            </button>
                          )}
                          <button
                            onClick={() => getPdf(cert.id)}
                            type="button"
                            className="inline-flex items-center px-2.5 py-1.5 ml-3 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-3 h-3 mr-1 text-fathom-blue"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                              />
                            </svg>
                            Download
                          </button>
                        </dd>
                      </div>
                      <div className="mt-2 flex items-center">
                        <CalendarIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-fathom-blue"
                          aria-hidden="true"
                        />
                        <p className="text-sm text-gray-500">
                          Course Completed{" "}
                          {new Date(cert.dateAwarded).toLocaleDateString(
                            "en-GB"
                          )}
                        </p>
                      </div>
                    </li>
                  )
              )}
            {selectedTab === 0 &&
              (!certs ||
                status !== "success" ||
                certs.data.filter((cert) => !cert.hasExpired).length === 0) && (
                <p className="text-center text-sm text-gray-400 p-5">
                  {" "}
                  - You have no active certifications, please check the expired
                  tab -
                </p>
              )}
          </ul>
        </div>
      </div>
      <RenewModal
        showModal={showModal}
        setShowModal={setShowModal}
        userLoggedIn={userLoggedIn}
        onSuccess={renewSuccess}
      />
    </div>
  );
}
