import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

/**
 * starts a module by setting it to INPROGRESS
 * @returns {any}
 */
export default function useUpdateModuleState() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ moduleStateId, currentState = null, selectedQuestionIds = null}) => {
      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;

        let data = {
          status: "INPROGRESS",
        }

        if(currentState) {
          data.state = currentState;
        }

        if(selectedQuestionIds) {
          data.selectedQuestionIds = selectedQuestionIds;
        }
      return axios.put(
        `${process.env.REACT_APP_API_ROOT}/user-modules/${moduleStateId}`,
        {
          data: data
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["user"]);
      },
    }
  );
}
